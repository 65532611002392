import { Box, Button, FormControl, FormLabel, Input, Flex, Heading, Image } from '@chakra-ui/react'
import ErrorMessage from './ErrorMessage'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/authService"
import image from "../images/globot.png"

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    //const [error, setError] = useState('');
    const { signin, isAuthenticated, error } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
          navigate("/ticket");
        }
    }, [isAuthenticated])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const user = {
            username: username,
            password: password
        }

        try {
            signin(user)
        } catch (error) {
            //setError('Usuario o contraseña inválidos')
            setUsername('')
            setPassword('')
        }
    };

    return (
        <>
            <Flex width="full" align="center" justifyContent="center" paddingTop="50px">
                <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
                    <Box width="100%">
                        <Image src={image} alt='Globot'/>
                    </Box>

                    <Box my={4} textAlign="left">
                        <form onSubmit={handleSubmit}>
                            {error && <ErrorMessage message={error} />}
                            <FormControl isRequired>
                                <FormLabel>Usuario</FormLabel>
                                <Input size="lg" onChange={event => setUsername(event.currentTarget.value)}/>
                            </FormControl>
                            <FormControl isRequired mt={6}>
                                <FormLabel>Contraseña</FormLabel>
                                <Input type="password" size="lg" onChange={event => setPassword(event.currentTarget.value)}/>
                            </FormControl>
                            
                            <Button colorScheme="teal" variant="outline" type="submit" width="full" mt={4}>Aceptar</Button>
                        </form>
                    </Box>
                </Box>
            </Flex>
        </>
    )
}

export default Login