import React, {useState, useEffect} from 'react'
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import './App.css';
import { ProtectedRoute } from "./routes"
import { AuthProvider } from "./services/authService"
import Sidebar from './components/Sidebar'
import Menu from './components/Menu'
import TicketList from './components/TicketList'
import Delivery from './components/Delivery'
import Categories from './components/Categories'
import Foods from './components/Foods'
import Configurations from './components/Configurations'
import General from './components/General'
import Login from './components/Login'
import TicketsListWeb from './components/TicketListWeb';
import TicketListLast from './components/TicketListLast';
import { Buffer } from 'buffer';
global.Buffer = Buffer;

function App() {
  return (
    <div className="App">
      
      <ChakraProvider>
        <AuthProvider>
          <BrowserRouter>
            <Sidebar/>
            <Routes>
              <Route path="/" element={<Login/>} />
              <Route path='/login' element={<Login/>} />
              <Route path='*' element={<Login/>}/>
              <Route element={<ProtectedRoute />}>
                <Route path='/menu' element={<Menu/>} />
                <Route path='/ticket' element={<TicketList/>} />
                <Route path='/delivery' element={<Delivery/>} />
                <Route path='/categories' element={<Categories/>} />
                <Route path='/foods' element={<Foods/>} />
                <Route path='/configurations' element={<Configurations/>} />
                <Route path='/general' element={<General/>} />
                <Route path='/ticketWeb' element={<TicketsListWeb/>} />
                <Route path='/ticketListLast' element={<TicketListLast/>} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ChakraProvider>
      
    </div>
  );
}

/*import React from 'react'
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import './App.css';
import Sidebar from './components/Sidebar'
import Menu from './components/Menu'
import TicketList from './components/TicketList'
import Delivery from './components/Delivery'
import Categories from './components/Categories'
import Foods from './components/Foods'
import Configurations from './components/Configurations'
import General from './components/General'
import Login from './components/Login'
import { Buffer } from 'buffer';
global.Buffer = Buffer;

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <BrowserRouter>
          <Sidebar/>
          <Routes>
            <Route path='*' element={<h1>NOT FOUND 404</h1>}/>
            <Route path='/' element={<TicketList/>} />
            <Route path='/menu' element={<Menu/>} />
            <Route path='/ticket' element={<TicketList/>} />
            <Route path='/delivery' element={<Delivery/>} />
            <Route path='/categories' element={<Categories/>} />
            <Route path='/foods' element={<Foods/>} />
            <Route path='/configurations' element={<Configurations/>} />
            <Route path='/general' element={<General/>} />
            <Route path='/login' element={<Login/>} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}*/

export default App;
