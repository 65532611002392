import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./services/authService";
import { CircularProgress } from "@chakra-ui/react"

export const ProtectedRoute = () => {
  const { isAuthenticated, loading } = useAuth();
  
  if (loading) return <CircularProgress/>;
  if (!isAuthenticated && !loading) return <Navigate to="/login" replace />;
  return <Outlet />;
};