import { Box, HStack, InputGroup, InputLeftAddon, useToast, Button, Stack, Input, Spacer, Textarea } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'

const General = () => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()
    const toast = useToast()
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    useEffect(() => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/configuration/general", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setValue("name", data.name)
            setValue("orderInterval", parseInt(data.orderInterval))
            setValue("orderQuantity", parseInt(data.orderQuantity))
            setValue("welcomeMessage", data.welcomeMessage)
            setValue("deliveryDelay", parseInt(data.deliveryDelay))
        })
        .catch(error => {
            logout()
        })
    },[])

    const onSubmit = (data) => {
        const accessToken = cookies.accessToken

        const configuration = {
            name: data.name,
            orderInterval: parseInt(data.orderInterval),
            orderQuantity: parseInt(data.orderQuantity),
            welcomeMessage: data.welcomeMessage,
            deliveryDelay: parseInt(data.deliveryDelay)
        }

        fetch("https://api.pedidoselchavo.com.ar/api/configuration/general", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(configuration)
        })
        .catch(error => {
            logout()
        })

        toast({
            title: "Los datos se cargaron correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });
    }

    return (
        <>
            <Box className="content">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={4}>
                        <InputGroup>
                            <InputLeftAddon children='Nombre comercial' width={295} />
                            <Input {...register('name', {required: 'Por favor ingrese el nombre comercial'})} />
                        </InputGroup>
                        {errors.name && <AlertPop title={errors.name.message} />}

                        <InputGroup>
                            <InputLeftAddon children='Mensaje de bienvenida' width={295} />
                            <Textarea {...register('welcomeMessage', {required: 'Por favor ingrese el mensaje de bienvenida'})} />
                        </InputGroup>
                        {errors.welcomeMessage && <AlertPop title={errors.welcomeMessage.message} />}

                        <InputGroup>
                            <InputLeftAddon children='Intervalo entrega de pedidos (minutos)' width={295} />
                            <Input placeholder='Minutos' type='number' {...register('orderInterval', {min: 0, required: 'El número debe ser mayor a 0'})} />
                        </InputGroup>
                        {errors.orderInterval && <AlertPop title={"El número debe ser mayor a 0"} />}

                        <InputGroup>
                            <InputLeftAddon children='Cantidad de pedidos por intervalo' width={295} />
                            <Input type='number' {...register('orderQuantity', {min: 0, required: 'El número debe ser mayor a 0'})} />
                        </InputGroup>
                        {errors.orderQuantity && <AlertPop title={"El número debe ser mayor a 0"} />}

                        <InputGroup>
                            <InputLeftAddon children='Demora horario delivery (minutos)' width={295} />
                            <Input placeholder='Minutos' type='number' {...register('deliveryDelay', {min: 0, required: 'El número debe ser mayor a 0'})} />
                        </InputGroup>
                        {errors.deliveryDelay && <AlertPop title={"El número debe ser mayor a 0"} />}

                        <Spacer/>
                        
                        <HStack>
                            <Button type='submit' colorScheme='blue'>Guardar</Button>
                            <a href="https://api.pedidoselchavo.com.ar/api/web/qr" target="_blank"><Button colorScheme='whatsapp'>Conectar WhatsApp</Button></a>
                        </HStack>

                        <Spacer/>
                    </Stack>
                
                </form>

            </Box>
        </>
    )
}

export default General