import React, {useEffect, useState} from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import { Modal, Button } from "react-bootstrap";
import { PDFViewer } from '@react-pdf/renderer';
import Ticket from './Ticket'
import PDF from './PDF'
import TicketAdd from './TicketAdd'

const TicketsListWeb = () => {
  const [tickets, setTickets] = useState([])
  const [showModalPDF, setShowModalPDF] = useState(false);
  const [print, setPrint] = useState(false)
  const [ticketAdd, setTicketAdd] = useState(false)
  const [isDelivered, setIsDelivered] = useState(false)
  let [ form, setForm ] = useState({})
  const { logout } = useAuth()
  const [cookies] = useCookies(['accessToken'])

  useEffect(() => {
    const accessToken = cookies.accessToken

    fetch("https://api.pedidoselchavo.com.ar/api/ticket/getTicketsWeb", {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": accessToken
        }
    })
    .then(response => response.json())
    .then(data => {
        const arrTickets = data.map(ticket => {
            return { 
                client: ticket.client,
                foods: ticket.foods,
                delivery: ticket.delivery,
                finalPrice: ticket.finalPrice,
                date: ticket.date,
                deliveryTime: ticket.deliveryTime,
                celNumber: ticket.celNumber,
                id: ticket.id,
                isPrint: ticket.isPrint,
                delivered: ticket.delivered,
                description: ticket.description
            }
        })
        setTickets(arrTickets)
    })
    .catch(error => {
        logout()
    })
  }, [print, ticketAdd, isDelivered]);

  const handleCloseModalPDF = () => {
    setPrint(!print)
    setShowModalPDF(false);
  }

  const handleShowModalPDF = (id, client, foods, delivery, finalPrice, date, deliveryTime, celNumber, isPrint, description) => {
    const accessToken = cookies.accessToken
    
    if (isPrint === false) {
        fetch('https://api.pedidoselchavo.com.ar/api/ticket/print/' + id, { 
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              "x-auth-token": accessToken
          }})
    }

    setForm(form = {
      id: id,
      client: client,
      foods: foods,
      delivery: delivery,
      finalPrice: finalPrice,
      deliveryTime: deliveryTime,
      date: date,
      celNumber: celNumber,
      description: description
    })
    setShowModalPDF(true);
  }

  const handleTicketAdd = () => {
    setTicketAdd(!ticketAdd)
  }

  const handleDelivered = (idTicket, celNumber) => {
    fetch("https://api.pedidoselchavo.com.ar/api/delivered/" + idTicket + "/" + celNumber, {})
    .catch(error => {
        console.log(error)
    })

    setIsDelivered(!isDelivered)
  }

  return (
    <div className='content'>
      <TicketAdd handleTicketAdd={handleTicketAdd}/>
      
      <div className="row g-3 padding-ticket-list">
          {tickets.map(ticket => <Ticket key={ticket.id} {...ticket} handleShowModalPDF={handleShowModalPDF} handleDelivered={handleDelivered} />)}
      </div>

      <Modal show={showModalPDF} onHide={handleCloseModalPDF} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Pedido N° {form.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PDFViewer width="720px" height="400px">
              <PDF {...form}/>
            </PDFViewer>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalPDF}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  )
}

export default TicketsListWeb