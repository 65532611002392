import { useToast, Text, Input, VStack, Button } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'

const Delivery = () => {
    const [deliveryPrice, setDeliveryPrice] = useState("")
    const [price, setPrice] = useState(0)
    const toast = useToast()
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    useEffect(() => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/delivery", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setDeliveryPrice(data[0].price)
        })
        .catch(error => {
            logout()
        })
    })

    const handleSubmit = () => {
        const accessToken = cookies.accessToken
        
        if (price === null || price === "" || parseFloat(price) < 1) {
            toast({
                title: "Ingrese correctamente el precio del delivery",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        const objPrice = {
            price: price
        }

        fetch("https://api.pedidoselchavo.com.ar/api/delivery/", { 
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(objPrice)}
            
        )
        .catch(error => {
            logout()
        })

        toast({
            title: "El precio se ha modificado correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });
    }

    return (
        <div className='content'>
            <VStack spacing={2} alignItems="left">
                <Text>Precio delivery</Text>
                <Input type="number" defaultValue={deliveryPrice} onChange={ e => setPrice(e.target.value) }></Input>
                <Button colorScheme='blue' width="90px" onClick={handleSubmit}>
                    Guardar
                </Button>
            </VStack>
        </div>
    )
}

export default Delivery