import { Card, Flex, Box, FormLabel, Menu, MenuItem, MenuList, MenuButton, Button, HStack, Checkbox, Divider,
        CircularProgress, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import { useState, useEffect } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useTable, useSortBy, usePagination } from "react-table"
import useColumnsTickets from "../hooks/useColumnsTickets"
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import TicketLast from './TicketLast'
const moment = require('moment')

const TicketListLast = () => {
    const [date, setDate] = useState(new Date());
    const [dropdown, setDropdown] = useState("Mediodía");
    const [viewIsCancel, setViewIsCancel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    useEffect(() => {
        moment.locale('en')
        const accessToken = cookies.accessToken
        setLoading(true)

        const dateSearch = Date.parse(moment(date).format('L'))
        const hour = dropdown === "Noche" ? "night" : "day"

        fetch("https://api.pedidoselchavo.com.ar/api/ticket/" + dateSearch + "/" + hour + "/" + viewIsCancel, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrTickets = data.map(ticket => {
                return {
                    _id: ticket.id,
                    id: ticket.id,
                    client: ticket.client,
                    celNumber: ticket.celNumber,
                    finalPrice: "$" + parseFloat(ticket.finalPrice).toFixed(2),
                    isCancel: ticket.isCancel ? "Cancelado": "Correcto"
                }
            })
            setData(arrTickets)
            setLoading(false)
        })
        .catch(error => {
            logout()
        })

    }, [date, dropdown, viewIsCancel])

    const columns = useColumnsTickets()
    const table = useTable({ columns, data, initialState: {pageIndex: 0, hiddenColumns: ["_id"]}}, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state
      } = table;

    const handleDate = (date) => {
        setDate(date || new Date())
    }

    return(
        <div className='content'>
            <Card align="center" variant="elevated" padding="10px" backgroundColor="#37A3A5">
                <Flex>
                    <HStack>
                        <Box width="30%">
                            <HStack>
                                <Box></Box>
                                <FormLabel>Fecha:</FormLabel>
                                <DatePicker locale='es' selected={date} onChange={(date) => handleDate(date)} dateFormat="dd/MM/yyyy" className="datePicker"/>
                            </HStack>
                        </Box>

                        <Divider orientation='vertical'/>

                        <Box width="30%">
                            <HStack>
                                <Box></Box>
                                <FormLabel>Horario:</FormLabel>
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm">
                                        {dropdown}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={() => setDropdown("Mediodía")}>Mediodía</MenuItem>
                                        <MenuItem onClick={() => setDropdown("Noche")}>Noche</MenuItem>
                                    </MenuList>
                                </Menu>
                            </HStack>
                        </Box>

                        <Divider orientation='vertical'/>

                        <Box width="30%">
                            <HStack>
                            <Checkbox onChange={() => setViewIsCancel(!viewIsCancel)}>
                                Mostrar pedidos cancelados
                            </Checkbox>
                            </HStack>
                        </Box>
                    </HStack>
                </Flex>
            </Card>

            {loading && <Box paddingTop="15px" textAlign='center'> 
                <CircularProgress isIndeterminate color='green.300' />
            </Box>}

            {!loading && <TableContainer paddingTop="15px">
                <Table variant='striped' size="md" colorScheme='blackAlpha' {...getTableProps()}>
                    <Thead>
                        {
                            // Recorremos las columnas que previamente definimos
                            headerGroups.map(headerGroup => (
                                // Añadimos las propiedades al conjunto de columnas
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    // Recorremos cada columna del conjunto para acceder a su información
                                    headerGroup.headers.map((column) => (
                                    // Añadimos las propiedades a cada celda de la cabecera
                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                        {
                                        // Pintamos el título de nuestra columna (propiedad "Header")
                                        column.render("Header")
                                        }
                                    </Th>
                                    ))
                                }
                                </Tr>
                            ))
                        }
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {
                            // Recorremos las filas
                            page.map(row => {
                                // Llamamos a la función que prepara la fila previo renderizado
                                prepareRow(row);
                                return (
                                // Añadimos las propiedades a la fila
                                <Tr {...row.getRowProps()}>
                                    {
                                    // Recorremos cada celda de la fila
                                    row.cells.map((cell) => {
                                        // Añadimos las propiedades a cada celda de la fila
                                        return (
                                        <Td {...cell.getCellProps()}>
                                            {
                                            // Pintamos el contenido de la celda
                                            cell.render("Cell")
                                            }
                                        </Td>
                                        );
                                    })
                                    }
                                    <Td>
                                        <HStack>
                                            <TicketLast idTicket={row.values.id}/>
                                        </HStack>
                                    </Td>
                                </Tr>
                                );
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>}
        </div>
    )
}

export default TicketListLast