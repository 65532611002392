import { useState, useEffect, useRef } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import { Table } from 'react-bootstrap'
import { CircularProgress, Box, InputGroup, InputLeftAddon, Select, AlertDialog, AlertDialogBody, 
    AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useDisclosure,
    HStack, Card, Flex, FormLabel, Divider, MenuButton, MenuItem, MenuList, Menu, Button } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import DatePicker from 'react-datepicker'
//import format from 'date-fns/format'
import MenuAdd3 from './MenuAdd3'
//import "react-datepicker/dist/react-datepicker.css"
const moment = require('moment')

const Menuu = () => {
    const [foodsMenu, setFoodsMenu] = useState([])
    const [titleDropdown, setTitleDropdown] = useState("Mediodía")
    const [startDate, setStartDate] = useState(new Date());
    //const [isOpen, setIsOpen] = useState(false);
    const [submitModal, setSubmitModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(null)
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
    const cancelRef = useRef()
    const [foodToRemove, setFoodToRemove] = useState("")
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    useEffect(() => {
        const accessToken = cookies.accessToken

        setLoading(true)

        fetch("https://api.pedidoselchavo.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            logout()
        })

        moment.locale('en')
        let hour
        let date

        hour = titleDropdown === "Noche" ? "night" : "day"
        date = Date.parse(moment(startDate).format('L'))
        
        fetch("https://api.pedidoselchavo.com.ar/api/menu/" + date + "/" + hour + "/" + category, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const foodsMenu = data.foods.map(food => {
                return { 
                    id: food.id,
                    subname: food.subname,
                    name: food.name,
                    price: food.price
                }
            })
            setFoodsMenu(foodsMenu)
            setLoading(false)
        })
        .catch(error => {
            logout()
        })
    }, [titleDropdown, startDate, submitModal])
    
    /*const handleChange = (e) => {
        setIsOpen(!isOpen);
        setStartDate(e);
    };*/

    /*const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };*/

    const changeValue = (e) => {
        setTitleDropdown(e.currentTarget.textContent)
    }

    const handleSubmitModal = (arrFoods, hour, date) => {
        setFoodsMenu(arrFoods)
        setTitleDropdown(hour)
        setStartDate(date)
    }
    
    const handleRemove = (subname) => {
        setFoodToRemove(subname)
        onOpenAlert()
    }

    const onChange = (e) => {
        setCategory(e.target.value || null)
        setSubmitModal(!submitModal)
    }

    const removeFood = () => {
        onCloseAlert()
        const accessToken = cookies.accessToken

        setLoading(true)

        const hour = (titleDropdown === "Mediodía") ? "day" : "night"
        const date = Date.parse(moment(startDate).format('L'))

        fetch("https://api.pedidoselchavo.com.ar/api/menu/" + date + "/" + hour + "/" + foodToRemove + "/" + category, { 
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const foodsMenu = data.foods.map(food => {
                return { 
                    id: food.id,
                    subname: food.subname,
                    name: food.name,
                    price: food.price
                }
            })
            setFoodsMenu(foodsMenu)
            setLoading(false)
        })
        .catch(error => {
            const foodsMenu = []
            setFoodsMenu(foodsMenu)
            setLoading(false)
        })
    }

    const removeFoodAll = () => {
        onCloseAlert()
        const accessToken = cookies.accessToken

        setLoading(true)

        const hour = (titleDropdown === "Mediodía") ? "day" : "night"

        //HAGO ESTE CALCULO PARA BORRAR LA COMIDA DESDE HOY HASTA EL ULTIMO DIA DEL AÑO
        const arrDays = []
        const today = new Date()
        const lastDay = new Date(today.getFullYear(), 11, 31)
        const diffDays = moment(lastDay).diff(moment(today), 'days')
        
        for (let i = 0; i <= diffDays + 1; i++){
            let dayOfWeek = moment(moment().add(i, 'd')).day()
            if (dayOfWeek === 0) dayOfWeek = 7
            const date = moment().add(i, 'd')
            arrDays.push(Date.parse(date))
        }
        //---------------------------------------------------------------------------------

        const menu = {
            date: arrDays,
            hour: hour,
            food: foodToRemove
        }

        fetch("https://api.pedidoselchavo.com.ar/api/menu/allDays", { 
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(menu)
        })
        .then(response => response.json())
        .then(data => {
            const foodsMenu = data.foods.map(food => {
                return { 
                    id: food.id,
                    subname: food.subname,
                    name: food.name,
                    price: food.price
                }
            })
            setFoodsMenu(foodsMenu)
            setLoading(false)
        })
        .catch(error => {
            const foodsMenu = []
            setFoodsMenu(foodsMenu)
            setLoading(false)
        })
    }
    

    return(
        <div className="content">
            <MenuAdd3 handleSubmitModal={handleSubmitModal}/>

            <br/>
            <br/>

            {/*<ButtonGroup>
                <span className="menu-style">Fecha:</span>
                <Button variant="primary" onClick={handleClick}>
                    {format(startDate, "dd/MM/yyyy")}
                </Button>
                {isOpen && (
                    <DatePicker selected={startDate} onChange={handleChange} inline />
                )}
                <DropdownButton className="menu-dropdown" title={titleDropdown} id="hour">
                    <Dropdown.Item eventKey="1" onClick={changeValue}>Mediodía</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={changeValue}>Noche</Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>

            <br/>

            <Box paddingTop="15px">
                <InputGroup>
                    <InputLeftAddon children='Categoría' width={140} />
                    <Select placeholder='-Todas-' onChange={(e) => onChange(e)}>
                    {categories.map(category => {
                        return (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    )})}
                    </Select>
                </InputGroup>
                        </Box>*/}

            <Card align="center" variant="elevated" padding="10px" backgroundColor="#37A3A5">
                <Flex>
                    <HStack textAlign="center">
                        <Box>
                            <HStack>
                                <Box></Box>
                                <FormLabel>Fecha:</FormLabel>
                                <DatePicker locale='es' selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd/MM/yyyy" className="datePicker"/>
                            </HStack>
                        </Box>

                        <Divider orientation='vertical'/>

                        <Box>
                            <HStack>
                                <Box></Box>
                                <FormLabel>Horario:</FormLabel>
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm">
                                        {titleDropdown}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={() => setTitleDropdown("Mediodía")}>Mediodía</MenuItem>
                                        <MenuItem onClick={() => setTitleDropdown("Noche")}>Noche</MenuItem>
                                    </MenuList>
                                </Menu>
                            </HStack>
                        </Box>

                        <Divider orientation='vertical'/>

                        <Box>
                            <HStack>
                                <Box></Box>
                                <FormLabel>Categoría:</FormLabel>
                                <Select placeholder='-Todas-' onChange={(e) => onChange(e)}>
                                {categories.map(category => {
                                    return (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                )})}
                                </Select>
                            </HStack>
                        </Box>
                    </HStack>
                </Flex>
            </Card>

            <br/>

            {loading && <Box paddingTop="15px" textAlign='center'> 
                <CircularProgress isIndeterminate color='green.300' />
            </Box>}

            {!loading && <Table>
                <thead>
                    <tr>
                    <th>Abreviatura</th>
                    <th>Comida</th>
                    <th>Precio</th>
                    <th/>
                    </tr>
                </thead>
                <tbody>
                    {foodsMenu.map(function(food, index) {
                        return ( 
                            <tr key={index}>
                                <td>{food.subname}</td>
                                <td>{food.name}</td>
                                <td>{food.price}</td>
                                <td><Button colorScheme="red" onClick={() => handleRemove(food.subname)} size="sm"><i className="bi bi-x-circle"></i></Button></td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </Table>}

            <br/>
            <br/>

            <AlertDialog
                    isOpen={isOpenAlert}
                    leastDestructiveRef={cancelRef}
                    onClose={onCloseAlert}
                    size="lg"
                >
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Eliminar comida de menú
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Está seguro que desea eliminar la comida del menú?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <HStack spacing={2}>
                                <Button ref={cancelRef} onClick={onCloseAlert}>
                                    Cancelar
                                </Button>
                                <Button colorScheme="red" onClick={removeFood} ml={3}>
                                    Eliminar sólo hoy
                                </Button>
                                <Button colorScheme="red" onClick={removeFoodAll} ml={3}>
                                    Eliminar todos los días
                                </Button>
                            </HStack>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            
        </div>
    )
}

export default Menuu