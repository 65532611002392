import { Stack, InputGroup, InputLeftAddon, Select, useDisclosure, useToast, Input, Spacer, Button,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex } from '@chakra-ui/react'
import { useState, useRef } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'

const FoodAdd = ({ handleSubmitModal }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const toast = useToast( )
    const [categories, setCategories] = useState([])
    const [subname, setSubname] = useState("")
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    const handleOpen = () => {
        const accessToken = cookies.accessToken

        onOpen()
        reset()
        setSubname("")

        fetch("https://api.pedidoselchavo.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            logout()
        })
    }

    const onSubmit = (food) => {
        const accessToken = cookies.accessToken

        food.subname = food.subname.toUpperCase()

        fetch("https://api.pedidoselchavo.com.ar/api/food/subname/" + food.subname, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data === null) {
                const newFood = {
                    subname: food.subname,
                    name: food.name,
                    description: food.description,
                    category: food.category,
                    price: parseFloat(food.price)
                }
        
                fetch("https://api.pedidoselchavo.com.ar/api/food/", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        "x-auth-token": accessToken
                    },
                    body: JSON.stringify(newFood)
                })
                .catch(error => {
                    logout()
                })
        
                toast({
                    title: "¡Comida añadida!",
                    status: "success",
                    duration: 3000,
                    isClosable: true
                });
        
                handleSubmitModal()
                onClose()
            } else {
                toast({
                    title: "Ya existe una comida con esa abreviatura",
                    status: "error",
                    duration: 3000,
                    isClosable: true
                });
            }
        })
        .catch(error => {
            logout()
        })
    }

    const onChange = (e) => {
        setSubname(e.target.value.toUpperCase())
    }

    return (
        <>
            <Button colorScheme="linkedin" onClick={handleOpen}>Agregar comida</Button>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Agregar comida</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={4}>
                        <InputGroup>
                            <InputLeftAddon children='Abreviatura' width={127} />
                            <Input ref={initialRef} value={subname} {...register('subname', {onChange: (e) => onChange(e), maxLength: 3, required: 'Ingrese la abreviatura'})} />
                        </InputGroup>
                        {errors.subname && <AlertPop title={errors.subname.message} />}

                        <InputGroup>
                            <InputLeftAddon children='Nombre' width={127} />
                            <Input {...register('name', {required: 'Ingrese el nombre'})} />
                        </InputGroup>
                        {errors.name && <AlertPop title={errors.name.message} />}

                        <InputGroup>
                            <InputLeftAddon children='Descripción' width={127} />
                            <Input {...register('description')} />
                        </InputGroup>

                        <InputGroup>
                            <InputLeftAddon children='Categoría' width={127} />
                            <Select placeholder='-Selecciona-' {...register('category', {required: 'Seleccione la categoría'})}>
                            {categories.map(category => {
                                return (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            )})}
                            </Select>
                        </InputGroup>
                        {errors.category && <AlertPop title={errors.category.message} />}

                        <InputGroup>
                            <InputLeftAddon children='Precio $' width={127} />
                            <Input type='number' {...register('price', {required: 'Ingrese el precio'})} />
                        </InputGroup>
                        {errors.price && <AlertPop title={errors.price.message} />}

                    </Stack>
                    <Flex p={4}>
                        <Spacer/>
                        <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                        <Button onClick={onClose}>Cancelar</Button>
                    </Flex>
                    
                </form>
                </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default FoodAdd