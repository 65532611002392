import { TYPES } from '../actions/cartAction'

export const cartInitialState = {
    data: []
}

export function cartReducer(state, action) {
    switch (action.type) {
        case TYPES.ADD_TO_CART: {
            return {
                ...state,
                data: [...state.data, {...action.payload} ]
            }
        }
        case TYPES.REMOVE_ONE_FROM_CART: {
            return {
                ...state,
                data: state.data.filter(obj => obj._id !== action.payload)
            }
        }
        case TYPES.CLEAR_CART: {
            return cartInitialState
        }
        default:
            return state
    }
}