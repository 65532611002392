import { Box, HStack, InputGroup, InputLeftAddon, Select, useToast, Button, Card, Heading, Divider } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
const moment = require('moment')
moment.locale('es')

const Configurations = () => {
    const [startDate, setStartDate] = useState([])
    const [closeDate, setCloseDate] = useState([])
    const [startNight, setStartNight] = useState([])
    const [closeNight, setCloseNight] = useState([])
    const [startDeliveryDate, setStartDeliveryDate] = useState([])
    const [closeDeliveryDate, setCloseDeliveryDate] = useState([])
    const [startDeliveryNight, setStartDeliveryNight] = useState([])
    const [closeDeliveryNight, setCloseDeliveryNight] = useState([])
    const toast = useToast()
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    useEffect(() => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/configuration/schedule", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrStartDate = data.map(date => {
                //const newDate = new Date(moment(date.hour.day.start, "HH:mm a"))
                return new Date(moment(date.hour.day.start, "HH:mm a"))
            })
            setStartDate(arrStartDate)

            const arrCloseDate = data.map(date => {
                //const newDate = new Date(moment(date.hour.day.end, "HH:mm a"))
                return new Date(moment(date.hour.day.end, "HH:mm a"))
            })
            setCloseDate(arrCloseDate)

            const arrStartNight = data.map(date => {
                //const newDate = new Date(moment(date.hour.night.start, "HH:mm a"))
                return new Date(moment(date.hour.night.start, "HH:mm a"))
            })
            setStartNight(arrStartNight)

            const arrCloseNight = data.map(date => {
                //const newDate = new Date(moment(date.hour.night.end, "HH:mm a"))
                return new Date(moment(date.hour.night.end, "HH:mm a"))
            })
            setCloseNight(arrCloseNight)

            const arrStartDeliveryDate = data.map(date => {
                //const newDate = new Date(moment(date.hourDelivery.day.start, "HH:mm a"))
                return new Date(moment(date.hourDelivery.day.start, "HH:mm a"))
            })
            setStartDeliveryDate(arrStartDeliveryDate)

            const arrCloseDeliveryDate = data.map(date => {
                //const newDate = new Date(moment(date.hourDelivery.day.end, "HH:mm a"))
                return new Date(moment(date.hourDelivery.day.end, "HH:mm a"))
            })
            setCloseDeliveryDate(arrCloseDeliveryDate)

            const arrStartDeliveryNight = data.map(date => {
                //const newDate = new Date(moment(date.hourDelivery.night.start, "HH:mm a"))
                return new Date(moment(date.hourDelivery.night.start, "HH:mm a"))
            })
            setStartDeliveryNight(arrStartDeliveryNight)

            const arrCloseDeliveryNight = data.map(date => {
                //const newDate = new Date(moment(date.hourDelivery.night.end, "HH:mm a"))
                return new Date(moment(date.hourDelivery.night.end, "HH:mm a"))
            })
            setCloseDeliveryNight(arrCloseDeliveryNight)
        })
        .catch(error => {
            logout()
        })
    }, [])

    const handleChangeStartDate = (index, newDate) => {
        const arrDate = startDate.map((d, i) => {
            if (i === index) {
                return newDate
            } else {
                return d
            }
        })
        setStartDate(arrDate)
    }

    const handleChangeCloseDate = (index, newDate) => {
        const arrDate = closeDate.map((d, i) => {
            if (i === index) {
                return newDate
            } else {
                return d
            }
        })
        setCloseDate(arrDate)
    }

    const handleChangeStartNight = (index, newDate) => {
        const arrDate = startNight.map((d, i) => {
            if (i === index) {
                return newDate
            } else {
                return d
            }
        })
        setStartNight(arrDate)
    }

    const handleChangeCloseNight = (index, newDate) => {
        const arrDate = closeNight.map((d, i) => {
            if (i === index) {
                return newDate
            } else {
                return d
            }
        })
        setCloseNight(arrDate)
    }

    const handleChangeStartDeliveryDate = (index, newDate) => {
        const arrDate = startDeliveryDate.map((d, i) => {
            if (i === index) {
                return newDate
            } else {
                return d
            }
        })
        setStartDeliveryDate(arrDate)
    }

    const handleChangeCloseDeliveryDate = (index, newDate) => {
        const arrDate = closeDeliveryDate.map((d, i) => {
            if (i === index) {
                return newDate
            } else {
                return d
            }
        })
        setCloseDeliveryDate(arrDate)
    }

    const handleChangeStartDeliveryNight = (index, newDate) => {
        const arrDate = startDeliveryNight.map((d, i) => {
            if (i === index) {
                return newDate
            } else {
                return d
            }
        })
        setStartDeliveryNight(arrDate)
    }

    const handleChangeCloseDeliveryNight = (index, newDate) => {
        const arrDate = closeDeliveryNight.map((d, i) => {
            if (i === index) {
                return newDate
            } else {
                return d
            }
        })
        setCloseDeliveryNight(arrDate)
    }

    const sync = (newStartDate, newCloseDate, newStartDeliveryDate, newCloseDeliveryDate, newStartNight, newCloseNight, newStartDeliveryNight, newCloseDeliveryNight) => {
        const arrStartDate = startDate.map(d => {
            return newStartDate
        })
        setStartDate(arrStartDate)

        const arrCloseDate = closeDate.map(d => {
            return newCloseDate
        })
        setCloseDate(arrCloseDate)

        const arrStartDeliveryDate = startDeliveryDate.map(d => {
            return newStartDeliveryDate
        })
        setStartDeliveryDate(arrStartDeliveryDate)

        const arrCloseDeliveryDate = closeDeliveryDate.map(d => {
            return newCloseDeliveryDate
        })
        setCloseDeliveryDate(arrCloseDeliveryDate)

        const arrStartNight = startNight.map(d => {
            return newStartNight
        })
        setStartNight(arrStartNight)

        const arrCloseNight = closeNight.map(d => {
            return newCloseNight
        })
        setCloseNight(arrCloseNight)

        const arrStartDeliveryNight = startDeliveryNight.map(d => {
            return newStartDeliveryNight
        })
        setStartDeliveryNight(arrStartDeliveryNight)

        const arrCloseDeliveryNight = closeDeliveryNight.map(d => {
            return newCloseDeliveryNight
        })
        setCloseDeliveryNight(arrCloseDeliveryNight)
    }

    const onSubmit = () => {
        const accessToken = cookies.accessToken
        const schedule = {
            startDate: startDate,
            closeDate: closeDate,
            startDeliveryDate: startDeliveryDate,
            closeDeliveryDate: closeDeliveryDate,
            startNight: startNight,
            closeNight: closeNight,
            startDeliveryNight: startDeliveryNight,
            closeDeliveryNight: closeDeliveryNight
        }

        fetch("https://api.pedidoselchavo.com.ar/api/configuration/schedule", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(schedule)
        })
        .catch(error => {
            logout()
        })

        toast({
            title: "Los datos se cargaron correctamente",
            status: "success",
            duration: 3000,
            isClosable: true
        });
    }

    return (
        <>
            <Box className="content">
                <Card variant="elevated" padding="10px">
                    <Box width="100%" textAlign="center">
                        <Heading size="md">Lunes</Heading>
                    </Box>
                    <Divider/>
                    <HStack>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Mediodía</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDate[0]}
                                            onChange={(date) => handleChangeStartDate(0, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDate[0]}
                                            onChange={(date) => handleChangeCloseDate(0, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryDate[0]}
                                            onChange={(date) => handleChangeStartDeliveryDate(0, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryDate[0]}
                                            onChange={(date) => handleChangeCloseDeliveryDate(0, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Noche</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startNight[0]}
                                            onChange={(date) => handleChangeStartNight(0, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeNight[0]}
                                            onChange={(date) => handleChangeCloseNight(0, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryNight[0]}
                                            onChange={(date) => handleChangeStartDeliveryNight(0, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryNight[0]}
                                            onChange={(date) => handleChangeCloseDeliveryNight(0, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                    </HStack>
                    <Box paddingTop="10px" textAlign="center">
                        <Button colorScheme="linkedin" width="200px" onClick={() => sync(startDate[0], closeDate[0], startDeliveryDate[0], closeDeliveryDate[0], startNight[0], closeNight[0], startDeliveryNight[0], closeDeliveryNight[0])}>Sincronizar días</Button>
                    </Box>
                </Card>
                <Box height="10px"/>
                <Card variant="elevated" padding="10px">
                    <Box width="100%" textAlign="center">
                        <Heading size="md">Martes</Heading>
                    </Box>
                    <Divider/>
                    <HStack>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Mediodía</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDate[1]}
                                            onChange={(date) => handleChangeStartDate(1, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDate[1]}
                                            onChange={(date) => handleChangeCloseDate(1, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryDate[1]}
                                            onChange={(date) => handleChangeStartDeliveryDate(1, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryDate[1]}
                                            onChange={(date) => handleChangeCloseDeliveryDate(1, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Noche</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startNight[1]}
                                            onChange={(date) => handleChangeStartNight(1, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeNight[1]}
                                            onChange={(date) => handleChangeCloseNight(1, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryNight[1]}
                                            onChange={(date) => handleChangeStartDeliveryNight(1, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryNight[1]}
                                            onChange={(date) => handleChangeCloseDeliveryNight(1, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                    </HStack>
                </Card>
                <Box height="10px"/>
                <Card variant="elevated" padding="10px">
                    <Box width="100%" textAlign="center">
                        <Heading size="md">Miércoles</Heading>
                    </Box>
                    <Divider/>
                    <HStack>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Mediodía</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDate[2]}
                                            onChange={(date) => handleChangeStartDate(2, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDate[2]}
                                            onChange={(date) => handleChangeCloseDate(2, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryDate[2]}
                                            onChange={(date) => handleChangeStartDeliveryDate(2, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryDate[2]}
                                            onChange={(date) => handleChangeCloseDeliveryDate(2, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Noche</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startNight[2]}
                                            onChange={(date) => handleChangeStartNight(2, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeNight[2]}
                                            onChange={(date) => handleChangeCloseNight(2, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryNight[2]}
                                            onChange={(date) => handleChangeStartDeliveryNight(2, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryNight[2]}
                                            onChange={(date) => handleChangeCloseDeliveryNight(2, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                    </HStack>
                </Card>
                <Box height="10px"/>
                <Card variant="elevated" padding="10px">
                    <Box width="100%" textAlign="center">
                        <Heading size="md">Jueves</Heading>
                    </Box>
                    <Divider/>
                    <HStack>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Mediodía</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDate[3]}
                                            onChange={(date) => handleChangeStartDate(3, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDate[3]}
                                            onChange={(date) => handleChangeCloseDate(3, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryDate[3]}
                                            onChange={(date) => handleChangeStartDeliveryDate(3, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryDate[3]}
                                            onChange={(date) => handleChangeCloseDeliveryDate(3, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Noche</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startNight[3]}
                                            onChange={(date) => handleChangeStartNight(3, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeNight[3]}
                                            onChange={(date) => handleChangeCloseNight(3, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryNight[3]}
                                            onChange={(date) => handleChangeStartDeliveryNight(3, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryNight[3]}
                                            onChange={(date) => handleChangeCloseDeliveryNight(3, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                    </HStack>
                </Card>
                <Box height="10px"/>
                <Card variant="elevated" padding="10px">
                    <Box width="100%" textAlign="center">
                        <Heading size="md">Viernes</Heading>
                    </Box>
                    <Divider/>
                    <HStack>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Mediodía</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDate[4]}
                                            onChange={(date) => handleChangeStartDate(4, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDate[4]}
                                            onChange={(date) => handleChangeCloseDate(4, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryDate[4]}
                                            onChange={(date) => handleChangeStartDeliveryDate(4, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryDate[4]}
                                            onChange={(date) => handleChangeCloseDeliveryDate(4, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Noche</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startNight[4]}
                                            onChange={(date) => handleChangeStartNight(4, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeNight[4]}
                                            onChange={(date) => handleChangeCloseNight(4, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryNight[4]}
                                            onChange={(date) => handleChangeStartDeliveryNight(4, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryNight[4]}
                                            onChange={(date) => handleChangeCloseDeliveryNight(4, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                    </HStack>
                </Card>
                <Box height="10px"/>
                <Card variant="elevated" padding="10px">
                    <Box width="100%" textAlign="center">
                        <Heading size="md">Sábado</Heading>
                    </Box>
                    <Divider/>
                    <HStack>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Mediodía</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDate[5]}
                                            onChange={(date) => handleChangeStartDate(5, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDate[5]}
                                            onChange={(date) => handleChangeCloseDate(5, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryDate[5]}
                                            onChange={(date) => handleChangeStartDeliveryDate(5, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryDate[5]}
                                            onChange={(date) => handleChangeCloseDeliveryDate(5, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Noche</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startNight[5]}
                                            onChange={(date) => handleChangeStartNight(5, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeNight[5]}
                                            onChange={(date) => handleChangeCloseNight(5, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryNight[5]}
                                            onChange={(date) => handleChangeStartDeliveryNight(5, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryNight[5]}
                                            onChange={(date) => handleChangeCloseDeliveryNight(5, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                    </HStack>
                </Card>
                <Box height="10px"/>
                <Card variant="elevated" padding="10px">
                    <Box width="100%" textAlign="center">
                        <Heading size="md">Domingo</Heading>
                    </Box>
                    <Divider/>
                    <HStack>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Mediodía</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDate[6]}
                                            onChange={(date) => handleChangeStartDate(6, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDate[6]}
                                            onChange={(date) => handleChangeCloseDate(6, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryDate[6]}
                                            onChange={(date) => handleChangeStartDeliveryDate(6, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryDate[6]}
                                            onChange={(date) => handleChangeCloseDeliveryDate(6, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                        <Box textAlign="center" paddingTop="10px" width="50%">
                            <Heading size="sm">Noche</Heading>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startNight[6]}
                                            onChange={(date) => handleChangeStartNight(6, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin pedidos' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeNight[6]}
                                            onChange={(date) => handleChangeCloseNight(6, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                            <HStack paddingLeft="5px" paddingRight="5px" paddingTop="5px">
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Inicio entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={startDeliveryNight[6]}
                                            onChange={(date) => handleChangeStartDeliveryNight(6, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Hora"
                                            dateFormat="HH:mm a"
                                            size="sm"
                                        />
                                    </HStack>
                                </Box>
                                <Box>
                                    <HStack spacing={1}>
                                        <InputGroup>
                                            <InputLeftAddon children='Fin entregas' width={130} />
                                        </InputGroup>
                                        <DatePicker
                                            selected={closeDeliveryNight[6]}
                                            onChange={(date) => handleChangeCloseDeliveryNight(6, date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm a"
                                        />
                                    </HStack>
                                </Box>
                            </HStack>
                        </Box>
                    </HStack>
                </Card>
                <Box height="10px"/>
                <Box textAlign="center">
                    <Button colorScheme="linkedin" onClick={onSubmit}>Guardar</Button>
                </Box>
                <Box height="10px"/>
            </Box>
        </>
    )
}

export default Configurations