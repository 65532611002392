import React, {useEffect, useState, useRef} from 'react'
import { useCookies } from 'react-cookie'
import { useAuth } from "../services/authService"
import { Modal, Button } from "react-bootstrap";
import { Box, HStack, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, 
        AlertDialogOverlay, useDisclosure } from '@chakra-ui/react'
import { PDFViewer } from '@react-pdf/renderer';
import Ticket from './Ticket'
import PDF from './PDF'
import TicketAdd from './TicketAdd';
import io from 'socket.io-client'
const ENDPOINT = "https://api.pedidoselchavo.com.ar/"

const Tickets = () => {
  const [tickets, setTickets] = useState([])
  const [showModalPDF, setShowModalPDF] = useState(false);
  //const [print, setPrint] = useState(false)
  //const [ticketAdd, setTicketAdd] = useState(false)
  //const [isDelivered, setIsDelivered] = useState(false)
  let [ form, setForm ] = useState({})
  const [connection, setConnection] = useState(false)
  const [cookies] = useCookies(['accessToken'])
  const { logout } = useAuth()
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
  const cancelRef = useRef()
  const [ticketToCancel, setTicketToCancel] = useState("")
  const [celNumberToCancel, setCelNumberToCancel] = useState("")

  useEffect(() => {
    const accessToken = cookies.accessToken

    if (accessToken) {
      const socket = io(ENDPOINT, {
        auth: {
          token: accessToken
        }
      });
      socket.on("FromAPI", data => {
        if (data[1] === null) {
          setConnection(false)
        } else {
          setConnection(true)
        }
        const tickets = data[0].map(ticket => {
          return {
            client: ticket.client,
            foods: ticket.foods,
            delivery: ticket.delivery,
            finalPrice: ticket.finalPrice,
            date: ticket.date,
            deliveryTime: ticket.deliveryTime,
            celNumber: ticket.celNumber,
            id: ticket.id,
            isPrint: ticket.isPrint,
            delivered: ticket.delivered,
            description: ticket.description
          }
        })
        setTickets(tickets)
      });
    } else {
      logout()
    }
    
  }, [/*print, ticketAdd, isDelivered*/]);

  const handleCloseModalPDF = () => {
    //setPrint(!print)
    setShowModalPDF(false);
  }

  const handleShowModalPDF = (id, client, foods, delivery, finalPrice, date, deliveryTime, celNumber, isPrint, description) => {
    /*const accessToken = cookies.accessToken
    
    if (isPrint === false) {
        fetch('https://api.pedidoselchavo.com.ar/api/ticket/print/' + id, { 
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              "x-auth-token": accessToken
          }})
    }*/
    if (isPrint === false) {
        fetch("https://api.pedidoselchavo.com.ar/api/printTicket/" + id, {})
        .catch(error => {
            console.log(error)
        })
    }

    setForm(form = {
      id: id,
      client: client,
      foods: foods,
      delivery: delivery,
      finalPrice: finalPrice,
      deliveryTime: deliveryTime,
      date: date,
      celNumber: celNumber,
      description: description
    })
    setShowModalPDF(true);
  }

  /*const handleTicketAdd = () => {
    //setTicketAdd(!ticketAdd)
  }*/

  const handleDelivered = (idTicket, celNumber) => {
    fetch("https://api.pedidoselchavo.com.ar/api/delivered/" + idTicket + "/" + celNumber, {})
    .catch(error => {
        console.log(error)
    })

    //setIsDelivered(!isDelivered)
  }

  const handleCancel = (idTicket, celNumber) => {
    setTicketToCancel(idTicket)
    setCelNumberToCancel(celNumber)
    onOpenAlert()
  }

  const cancelTicket = () => {
    fetch("https://api.pedidoselchavo.com.ar/api/cancelTicket/" + ticketToCancel + "/" + celNumberToCancel, {})
    .catch(error => {
        console.log(error)
    })
    onCloseAlert()
  }

  return (
    <div className='content'>
      {/*<TicketAdd handleTicketAdd={handleTicketAdd}/> 7 04/07*/}
      <HStack>
          <TicketAdd/>
          {connection && <Box bg='#04AA6D' width='170px' color='white' height='30px' paddingLeft='5px' borderRadius='10px'>WhatsApp conectado</Box>}
          {!connection && <Box bg='#da0000' width='185px' color='white' height='30px' paddingLeft='5px' borderRadius='10px'>WhatsApp desconectado</Box>}
      </HStack>

      <div className="row g-3 padding-ticket-list">
          {tickets.map(ticket => <Ticket key={ticket.id} {...ticket} handleShowModalPDF={handleShowModalPDF} handleDelivered={handleDelivered} handleCancel={handleCancel} />)}
      </div>

      <Modal show={showModalPDF} onHide={handleCloseModalPDF} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Pedido N° {form.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PDFViewer width="720px" height="400px">
              <PDF {...form}/>
            </PDFViewer>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalPDF}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        <AlertDialog
              isOpen={isOpenAlert}
              leastDestructiveRef={cancelRef}
              onClose={onCloseAlert}
              size="lg"
          >
              <AlertDialogOverlay>
              <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                      Cancelar pedido
                  </AlertDialogHeader>

                  <AlertDialogBody>
                      ¿Está seguro que desea cancelar el pedido?
                  </AlertDialogBody>

                  <AlertDialogFooter>
                      <HStack spacing={2}>
                          <Button variant='secondary' ref={cancelRef} onClick={onCloseAlert}>
                              No
                          </Button>
                          <Button variant='danger' onClick={cancelTicket} ml={3}>
                              Si
                          </Button>
                      </HStack>
                  </AlertDialogFooter>
              </AlertDialogContent>
          </AlertDialogOverlay>
      </AlertDialog>
    </div>
  )
}

export default Tickets