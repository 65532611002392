import { useMemo } from "react";

export default function useColumnsFoods() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "id"
     },
     {
       Header: "Abrev.",
       accessor: "subname"
     },
     {
       Header: "Comida",
       accessor: "name"
     },
     {
       Header: "Precio",
       accessor: "price"
     }
   ],
   []
 );

 return columns;
}