import { Stack, InputGroup, InputLeftAddon, Select, useDisclosure, useToast, Input, Spacer, Button,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, Table, Thead, 
    Tbody, Tr, Th, Td, TableContainer, FormLabel, Tooltip, AlertDialog, AlertDialogBody, AlertDialogFooter, 
    AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react'
import { useState, useRef, useReducer } from 'react'
import { TYPES } from '../actions/cartAction'
import { useTable, usePagination, useSortBy } from "react-table"
import { cartInitialState, cartReducer } from '../reducers/cartReducer'
import { DeleteIcon } from '@chakra-ui/icons'
import TicketAddFood from './TicketAddFood'
import useColumnsTicket from "../hooks/useColumnsTicket"
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'
const moment = require('moment')

const TicketAdd = (/*{ handleTicketAdd }*/) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
    const initialRef = useRef(null)
    const cancelRefAlert = useRef()
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm()
    const toast = useToast()
    const [stateReducer, dispatch] = useReducer(cartReducer, cartInitialState)
    const { data } = stateReducer
    const [totalTicket, setTotalTicket] = useState(0)
    const [deliveryTime, setDeliveryTime] = useState([])
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    const handleOpen = () => {
        const accessToken = cookies.accessToken

        dispatch({ type:TYPES.CLEAR_CART })
        onOpen()
        reset()

        fetch("https://api.pedidoselchavo.com.ar/api/delivery", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setValue("delivery", data[0].price)
            setTotalTicket(data[0].price)
        })
        .catch(error => {
            logout()
        })

        fetch("https://api.pedidoselchavo.com.ar/api/configuration/getDeliveryTime", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrDeliveryTime = data.map(deliveryTime => {
                return { 
                    index: deliveryTime.index,
                    hour: deliveryTime.hour
                }
            })
            setDeliveryTime(arrDeliveryTime)
        })
        .catch(error => {
            logout()
        })
    }

    const columns = useColumnsTicket();
    const table = useTable({ columns, data, initialState: {pageIndex: 0, hiddenColumns: ["_id"]}}, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = table

    const onSubmit = () => {
        if (data.length < 1) {
            toast({
                title: "Ingrese la comida para dar de alta un pedido",
                status: "error",
                duration: 5000,
                isClosable: true
            });
            return
        }

        onOpenAlert()
    }

    const confirmTicket = () => {
        const accessToken = cookies.accessToken
        moment.locale("en")
        const arrFoods = []
        let turn
        let count = 0

        const indexDeliveryTime = deliveryTime.find(obj => obj.hour === getValues("deliveryTime"))

        for (let i=0; i < data.length; i++) {
            arrFoods.push({
                _id: data[i]._id,
                count: parseInt(data[i].count),
                subname: data[i].subname,
                name: data[i].name,
                price: parseFloat(data[i].price),
                finalPrice: parseFloat(data[i].totalPrice)
            })

            count = count + parseInt(data[i].count)
        }

        if (moment().hour() >= 16) {
            turn = "night"
        } else {
            turn = "day"
        }

        const ticket = {
            client: getValues("client"),
            foods: arrFoods,
            delivery: parseFloat(getValues("delivery")),
            finalPrice: parseFloat(totalTicket),
            date: moment().format("L"),
            turn: turn,
            deliveryTime: getValues("deliveryTime"),
            celNumber: getValues("celNumber"),
            isPrint: false,
            delivered: false,
            isWeb: false,
            description: getValues("description"),
            isCancel: false
        }

        const dataDeliveryTime = {
            turn: turn,
            indexDeliveryTime: indexDeliveryTime.index,
            count: parseInt(count)
        }

        fetch("https://api.pedidoselchavo.com.ar/api/menu/changeDeliveryTime", {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(dataDeliveryTime)
        })
        .catch(error => {
            console.log(error)
        })

        fetch("https://api.pedidoselchavo.com.ar/api/addTicket/" + ticket.celNumber, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken //4 04/07
            },
            body: JSON.stringify(ticket)
        })
        .catch(error => {
            console.log(error)
        })

        /*fetch("https://api.pedidoselchavo.com.ar/api/ticket", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken //4 04/07
            },
            body: JSON.stringify(ticket)
        })
        .catch(error => {
            logout()
        })

        fetch("https://api.pedidoselchavo.com.ar/api/addTicket/" + celNumber, {})
        .catch(error => {
            console.log(error)
        })*/

        //handleTicketAdd()
        onCloseAlert()
        onClose()
    }

    const searchClient = () => {
        const accessToken = cookies.accessToken

        const celNumber = getValues("celNumber")
        
        if (celNumber != "") {
            fetch("https://api.pedidoselchavo.com.ar/api/client/" + celNumber, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data !== null) {
                    setValue("client", data.description)
                }
            })
            .catch(error => {
                logout()
            })
        }
    }

    const addToCart = (food) => {
        dispatch({ type:TYPES.ADD_TO_CART, payload: food })
        calculatingTotalPrice(food, true)
    }

    const removeFromCart = (food) => {
        dispatch({ type:TYPES.REMOVE_ONE_FROM_CART, payload: food._id })
        calculatingTotalPrice(food, false)
    }

    const calculatingTotalPrice = (food, add) => {
        let totalPrice = 0
        
        if (getValues("delivery") != "") {
            totalPrice = totalPrice + parseFloat(getValues("delivery"))
        }

        for (let i=0; i < data.length; i++) {
            totalPrice = totalPrice + parseFloat(data[i].totalPrice)
        }
        
        if (add === true) {
            totalPrice = totalPrice + parseFloat(food.totalPrice)
        } else if (add === false) {
            totalPrice = totalPrice - parseFloat(food.totalPrice)
        }

        setTotalTicket(parseFloat(totalPrice).toFixed(2))
    }

    return (
        <>
            <Button colorScheme="linkedin" onClick={handleOpen}>Nuevo pedido</Button>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Nuevo pedido</ModalHeader>
                <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={4}>
                                <InputGroup>
                                    <InputLeftAddon children='N° de celular' width={155} />
                                    <Input ref={initialRef} type='number' {...register('celNumber', {onBlur: searchClient, required: 'Ingrese el número de celular'})} />
                                </InputGroup>
                                {errors.celNumber && <AlertPop title={errors.celNumber.message} />}

                                <InputGroup>
                                    <InputLeftAddon children='Cliente y dirección' width={155} />
                                    <Input {...register('client', {required: 'Ingrese el nombre y dirección del cliente'})} />
                                </InputGroup>
                                {errors.client && <AlertPop title={errors.client.message} />}

                                <InputGroup>
                                    <InputLeftAddon children='Horario de entrega' width={155} />
                                    <Select placeholder='-Selecciona-' {...register('deliveryTime', {required: 'Por favor seleccione el horario'})}>
                                    {deliveryTime.map(deliveryTime => {
                                        return (
                                        <option key={deliveryTime.index} value={deliveryTime.hour}>{deliveryTime.hour}</option>
                                    )})}
                                    </Select>
                                </InputGroup>
                                {errors.deliveryTime && <AlertPop title={errors.deliveryTime.message} />}

                                <InputGroup>
                                    <InputLeftAddon children='Delivery $' width={155} />
                                    <Input type='number' {...register('delivery', {onBlur: calculatingTotalPrice, required: 'Ingrese el costo del delivery'})} />
                                </InputGroup>
                                {errors.delivery && <AlertPop title={errors.delivery.message} />}

                                <InputGroup>
                                    <InputLeftAddon children='Descripción' width={155} />
                                    <Input {...register('description')} />
                                </InputGroup>
                                {errors.description && <AlertPop title={errors.description.message} />}

                                <TableContainer paddingTop="15px">
                                    <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                        <Thead>
                                            {
                                                // Recorremos las columnas que previamente definimos
                                                headerGroups.map(headerGroup => (
                                                    // Añadimos las propiedades al conjunto de columnas
                                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                                    {
                                                        // Recorremos cada columna del conjunto para acceder a su información
                                                        headerGroup.headers.map((column) => (
                                                        // Añadimos las propiedades a cada celda de la cabecera
                                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                            {
                                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                                            column.render("Header")
                                                            }
                                                        </Th>
                                                        ))
                                                    }
                                                    </Tr>
                                                ))
                                            }
                                        </Thead>
                                        <Tbody {...getTableBodyProps()}>
                                            {
                                                // Recorremos las filas
                                                page.map(row => {
                                                    // Llamamos a la función que prepara la fila previo renderizado
                                                    prepareRow(row);
                                                    return (
                                                    // Añadimos las propiedades a la fila
                                                    <Tr {...row.getRowProps()}>
                                                        {
                                                        // Recorremos cada celda de la fila
                                                        row.cells.map((cell) => {
                                                            // Añadimos las propiedades a cada celda de la fila
                                                            return (
                                                            <Td {...cell.getCellProps()}>
                                                                {
                                                                // Pintamos el contenido de la celda
                                                                cell.render("Cell")
                                                                }
                                                            </Td>
                                                            );
                                                        })
                                                        }
                                                        <Td>
                                                            <Tooltip label='Eliminar'><Button size="sm" colorScheme="red" onClick={() => removeFromCart(row.values)}><DeleteIcon/></Button></Tooltip>
                                                        </Td>
                                                    </Tr>
                                                    );
                                                })
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                                <Flex p={4}>
                                    <Spacer/>
                                    <FormLabel textAlign="right">Total: </FormLabel>
                                    <FormLabel textAlign="left">${totalTicket}</FormLabel>
                                </Flex>

                                <TicketAddFood addToCart={addToCart}/>

                            </Stack>
                            <Flex p={4}>
                                <Spacer/>
                                <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                                <Button onClick={onClose}>Cancelar</Button>
                            </Flex>
                            
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRefAlert}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Confirmar pedido
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Desea confirmar el pedido?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                        <Button ref={cancelRefAlert} onClick={onCloseAlert}>
                            No
                        </Button>
                        <Button colorScheme='blue' onClick={confirmTicket} ml={3}>
                            Confirmar
                        </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default TicketAdd