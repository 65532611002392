import { Box } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import Category from './Category'
import CategoryAdd from './CategoryAdd'

const Categories = () => {
    const [categories, setCategories] = useState([])
    const [submitModal, setSubmitModal] = useState(false)
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    useEffect(() => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return {
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            logout()
        })
    }, [submitModal])

    const handleSubmitModal = () => {
        setSubmitModal(!submitModal)
    }

    return(
        <>
            <Box className="content">
                <CategoryAdd handleSubmitModal={handleSubmitModal}/>

                <Box paddingTop="15px">

                    <Box className="row g-3">
                        {categories.map(category => <Category key={category.id} {...category} handleSubmitModal={handleSubmitModal} />)}
                    </Box>

                </Box>

            </Box>
        </>
    )
}

export default Categories