import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { useEffect, useState } from 'react'
import { useAuth } from "../services/authService"

const Sidebars = styled.div`
    margin: 0;
    padding: 10px;
    width: 250px;
    background-color: #212529;
    position: fixed;
    height: 100%;
    overflow: auto;

    @media screen and (max-width: 700px) {
        width: 100%;
        height: auto;
        position: relative;
    }
`

const Span = styled.span`
    padding: 10px;
    color: #ffffff;
    font-family: var(--bs-body-font-family);
`

const A = styled.a`
    display: block;
    color: #ffffff;
    padding: 16px;
    text-decoration: none;
    background-color: transparent;

    &.active {
        background-color: #04AA6D;
    }

    &:hover {
        background-color: #555;
        color: white;
        transition: 0.5s all ease;
        cursor: pointer;
    }

    &.visible {
        display: none;
    }

    @media screen and (max-width: 700px) {
        float: left;
    }

    @media screen and (max-width: 400px) {
        text-align: center;
        float: none;
    }
`

const Hr = styled.hr`
    color: #ffffff;
`

const DropdownContainer = styled.div`
  display: block;
  background-color: transparent;
  color: white;
  padding-left: 8px;
  display: ${({ dropdown }) => (dropdown ? 'block' : 'none')};
  transition: 350ms;
  z-index: 10;
`;



const Sidebar = () => {
    let [dropdown, setDropdown] = useState(false)
    const { isAuthenticated, logout } = useAuth()
    
    
    const showDropdown = (children) => {
        if (children.children[1] === "Configuraciones") {
            setDropdown(!dropdown)
        } else if (children.children[1] === "Días laborales" || children.children[1] === "Config. generales") {
            setDropdown(dropdown = true)
        } else {
            setDropdown(dropdown = false)
        }
    }

    function Link({ as, className, href, to, children }) {
        return (
          <A
            as={as}
            activeclassname="active"
            className={className}
            href={href}
            to={to}
            onClick={() => check(children)}
            //onClick={e => showDropdown({children})}
          >
            {children}
          </A>
        );
      }

    function check(children) {
        if (children[1] === "Cerrar sesión") logout()
        showDropdown({children})
    }

    return(
        <>
            {isAuthenticated && <Sidebars>
                <p href="/">
                    <Span className="fs-4">
                        Globot
                    </Span>
                </p>

                <Hr></Hr>

                <Link as={NavLink} to="ticket">
                    <i className="bi bi-calendar3 icon-sidebar"></i>
                    Pedidos
                </Link>
                <Link as={NavLink} to="ticketListLast">
                    <i className="bi bi-calendar-check icon-sidebar"></i>
                    Pedidos anteriores
                </Link>
                <Link as={NavLink} to="menu">
                    <i className="bi bi-clipboard2-fill icon-sidebar"></i>
                    Menú
                </Link>
                <Link as={NavLink} to="foods">
                    <i className="bi bi-egg-fried icon-sidebar"></i>
                    Comidas
                </Link>
                <Link as={NavLink} to="categories">
                    <i className="bi bi-calendar3 icon-sidebar"></i>
                    Categorías
                </Link>
                <Link as={NavLink} to="delivery">
                    <i className="bi bi-bicycle icon-sidebar"></i>
                    Delivery
                </Link>
                <Link>
                    <i className="bi bi-gear icon-sidebar"></i>
                    Configuraciones
                    <i className="bi bi-caret-down-fill icon-sidebar-down"></i>
                </Link>
                <DropdownContainer dropdown={dropdown}>
                    <Link as={NavLink} to="configurations">
                        <i className="bi bi-dot icon-dropdown"></i>
                        Días laborales
                    </Link>
                    <Link as={NavLink} to="general">
                        <i className="bi bi-dot icon-dropdown"></i>
                        Config. generales
                    </Link>
                </DropdownContainer>
                <Link to="/">
                    <i className="bi bi-box-arrow-left icon-sidebar"></i>
                    Cerrar sesión
                </Link>
            </Sidebars>}
        </>
    )
}

export default Sidebar