import { Button, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
    AlertDialogOverlay, useDisclosure, useToast } from "@chakra-ui/react"
import { useRef } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'

const CategoryRemove = ({ idCategory, handleSubmitEdit }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const toast = useToast()
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    const handleOpen = () => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/food/category/" + idCategory, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.length === 0) {
                onOpen()
                return
            }
            toast({
                title: "No puede eliminar la categoría porque tiene comidas asociadas",
                status: "error",
                duration: 3000,
                isClosable: true
            });
        })
        .catch(error => {
            logout()
        })

    }

    const handleConfirm = () => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/category/" + idCategory, { 
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            "x-auth-token": accessToken
            }
        })

        handleSubmitEdit()
        onClose()
    }

    return (
        <>
            <Button colorScheme="red" onClick={handleOpen}>Eliminar</Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Eliminar categoría
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar la operación?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button colorScheme='blue' onClick={handleConfirm} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default CategoryRemove