import { Stack, InputGroup, InputLeftAddon, Select, useDisclosure, useToast, Input, Spacer, Button,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Tooltip,
    CircularProgress, HStack, Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react'
import { useState } from 'react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table"
import { BsCheckCircle } from "react-icons/bs"
import { GlobalFilter } from './GlobalFilter'
import useColumnsFoods from "../hooks/useColumnsFoods"
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
const moment = require('moment')

const TicketAddFood = ({ addToCart }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [data, setData] = useState([])
    const [categories, setCategories] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    const columns = useColumnsFoods()
    const table = useTable({ columns, data, initialState: {pageSize: 10, pageIndex: 0, hiddenColumns: ["id"]}}, useGlobalFilter, useSortBy, usePagination );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        state,
        setGlobalFilter
      } = table;

      const { globalFilter } = state

    const handleOpen = () => {
        moment.locale('en')
        const accessToken = cookies.accessToken

        setLoading(true)

        fetch("https://api.pedidoselchavo.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            logout()
        })

        let hour
        let date
        
        hour = (moment().hour()) >= 16 ? "night" : "day"
        date = Date.parse(moment(new Date()).format('L'))
        
        fetch("https://api.pedidoselchavo.com.ar/api/menu/" + date + "/" + hour + "/" + "null", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const foodsMenu = data.foods.map(food => {
                return { 
                    id: food.id,
                    subname: food.subname,
                    name: food.name,
                    price: food.price
                }
            })
            setData(foodsMenu)
            setLoading(false)
        })
        .catch(error => {
            const foodsMenu = []
            setData(foodsMenu)
            setLoading(false)
        })

        /*fetch("https://api.pedidoselchavo.com.ar/api/food/category/null", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrFoods = data.map(food => {
                return { 
                    id: food._id,
                    subname: food.subname,
                    name: food.name,
                    price: parseFloat(food.price)
                }
            })
            setData(arrFoods)
            setLoading(false)
        })
        .catch(error => {
            /*authService.logout()
            navigate("/");
            window.location.reload();
        })*/

        setQuantity(0)
        onOpen()
    }

    const handleChangeCategory = (category) => {
        const accessToken = cookies.accessToken

        if (category === "") category = "null"

        setLoading(true)

        let hour
        let date
        
        hour = (moment().hour()) >= 16 ? "night" : "day"
        date = Date.parse(moment(new Date()).format('L'))
        
        fetch("https://api.pedidoselchavo.com.ar/api/menu/" + date + "/" + hour + "/" + category, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const foodsMenu = data.foods.map(food => {
                return { 
                    id: food.id,
                    subname: food.subname,
                    name: food.name,
                    price: food.price
                }
            })
            setData(foodsMenu)
            setLoading(false)
        })
        .catch(error => {
            const foodsMenu = []
            setData(foodsMenu)
            setLoading(false)
        })

        /*fetch("https://api.pedidoselchavo.com.ar/api/food/category/" + category, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrFoods = data.map(food => {
                return { 
                    id: food._id,
                    subname: food.subname,
                    name: food.name,
                    price: parseFloat(food.price)
                }
            })
            setData(arrFoods)
            setLoading(false)
        })
        .catch(error => {
            authService.logout()
            navigate("/");
            window.location.reload();
        })*/
    }

    const addFood = (row) => {
        
        if (quantity <= 0) {
            toast({
                title: "La cantidad tiene que ser mayor a 0",
                status: "error",
                duration: 5000,
                isClosable: true
            });
            return
        }

        const food = {
            _id: row.values.id,
            subname: row.values.subname,
            name: row.values.name,
            count: parseInt(quantity),
            price: parseFloat(row.values.price).toFixed(2),
            totalPrice: parseFloat(parseFloat(quantity) * parseFloat(row.values.price)).toFixed(2)
        }

        addToCart(food)
        setQuantity(0)

        handleOpen()
    }

    return(
        <>
            <Button colorScheme="whatsapp" onClick={handleOpen}>Agregar comida</Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Agregar comida</ModalHeader>
                <ModalCloseButton />
                    <ModalBody pb={6}>
                            <Stack spacing={4}>

                                <Box paddingTop="10px">
                                    <GlobalFilter filter={ globalFilter } setFilter={ setGlobalFilter } />
                                </Box>

                                <HStack>
                                    <span className="menu-style">Categoría:</span>
                                    <Select placeholder='-Selecciona-' onChange={(e) => handleChangeCategory(e.target.value)}>
                                    {categories.map(category => {
                                        return (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    )})}
                                    </Select>
                                </HStack>

                                {loading && <Box paddingTop="15px" textAlign='center'> 
                                    <CircularProgress isIndeterminate color='green.300' />
                                </Box>}

                                {!loading && <TableContainer paddingTop="15px">
                                    <Table variant='striped' size="md" colorScheme='blackAlpha' {...getTableProps()}>
                                        <Thead>
                                            {
                                                // Recorremos las columnas que previamente definimos
                                                headerGroups.map(headerGroup => (
                                                    // Añadimos las propiedades al conjunto de columnas
                                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                                    {
                                                        // Recorremos cada columna del conjunto para acceder a su información
                                                        headerGroup.headers.map((column) => (
                                                        // Añadimos las propiedades a cada celda de la cabecera
                                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                            {
                                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                                            column.render("Header")
                                                            }
                                                        </Th>
                                                        ))
                                                    }
                                                    </Tr>
                                                ))
                                            }
                                        </Thead>
                                        <Tbody {...getTableBodyProps()}>
                                            {
                                                // Recorremos las filas
                                                page.map(row => {
                                                    // Llamamos a la función que prepara la fila previo renderizado
                                                    prepareRow(row);
                                                    return (
                                                    // Añadimos las propiedades a la fila
                                                    <Tr {...row.getRowProps()}>
                                                        {
                                                        // Recorremos cada celda de la fila
                                                        row.cells.map((cell) => {
                                                            // Añadimos las propiedades a cada celda de la fila
                                                            return (
                                                            <Td {...cell.getCellProps()}>
                                                                {
                                                                // Pintamos el contenido de la celda
                                                                cell.render("Cell")
                                                                }
                                                            </Td>
                                                            );
                                                        })
                                                        }
                                                        <Td>
                                                            <HStack>
                                                                <Input placeholder="Cantidad" width={100} backgroundColor="white" type='number' textAlign="center" onChange={(e) => setQuantity(e.target.value)}/>
                                                                <Tooltip label='Añadir al carrito'><Button size="sm" colorScheme="green" onClick={() => addFood(row)}><BsCheckCircle/></Button></Tooltip>
                                                            </HStack>
                                                        </Td>
                                                    </Tr>
                                                    );
                                                })
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>}

                                <Box paddingTop="10px" textAlign="center">
                                    <Button marginRight="10px" colorScheme="facebook" onClick={() => previousPage()}>Anterior</Button>
                                    <Button colorScheme="facebook" onClick={() => nextPage()}>Siguiente</Button>
                                </Box>
                                

                            </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default TicketAddFood