import { Button, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
    AlertDialogOverlay, useDisclosure } from "@chakra-ui/react"
import { useRef } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'

const FoodRemove = ({ idFood, handleSubmitEdit }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    const handleConfirm = () => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/food/" + idFood, { 
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            "x-auth-token": accessToken
            }
        })
        .catch(error => {
            logout()
        })

        handleSubmitEdit()
        onClose()
    }

    return (
        <>
            <Button colorScheme="red" onClick={onOpen}>Eliminar</Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Eliminar comida
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        ¿Desea confirmar la operación?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button colorScheme='blue' onClick={handleConfirm} ml={3}>
                        Confirmar
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default FoodRemove