import { Stack, InputGroup, InputLeftAddon, Box, useDisclosure, Input, Spacer, Button,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, Table, Thead, 
    Tbody, Tr, Th, Td, TableContainer, FormLabel, CircularProgress, Tooltip } from '@chakra-ui/react'
import { useState, useRef } from 'react'
import { useTable, usePagination, useSortBy } from "react-table"
import { SearchIcon } from '@chakra-ui/icons'
import useColumnsTicket from "../hooks/useColumnsTicket"
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'

const TicketLast = ({ idTicket }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, formState: { errors }, setValue } = useForm()
    const [data, setData] = useState([])
    const [totalTicket, setTotalTicket] = useState("")
    const [loading, setLoading] = useState(false)
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    const handleOpen = () => {
        const accessToken = cookies.accessToken
        onOpen()
        setLoading(true)
        
        fetch("https://api.pedidoselchavo.com.ar/api/ticket/" + idTicket, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setValue("celNumber", data.celNumber)
            setValue("client", data.client)
            setValue("deliveryTime", data.deliveryTime)
            setValue("delivery", data.delivery)
            setValue("description", data.description)
            setTotalTicket(parseFloat(data.finalPrice).toFixed(2))

            const arrFoods = data.foods.map(food => {
                return { 
                    _id: food._id,
                    count: parseInt(food.count),
                    subname: food.subname,
                    name: food.name,
                    price: "$" + parseFloat(food.price).toFixed(2),
                    totalPrice: "$" + parseFloat(food.finalPrice).toFixed(2)
                }
            })
            setData(arrFoods)

            setLoading(false)
        })
        .catch(error => {
            logout()
        })
    }

    const columns = useColumnsTicket();
    const table = useTable({ columns, data, initialState: {pageIndex: 0, hiddenColumns: ["_id"]}}, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = table

    return (
        <>
            <Tooltip label='Ver pedido'><Button onClick={handleOpen}><SearchIcon/></Button></Tooltip>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='3xl'
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Pedido N° {idTicket}</ModalHeader>
                <ModalCloseButton />
                    <ModalBody pb={6}>
                        {loading && <Box paddingTop="15px" textAlign='center'> 
                            <CircularProgress isIndeterminate color='green.300' />
                        </Box>}

                        {!loading && <form>
                            <Stack spacing={4}>
                                <InputGroup>
                                    <InputLeftAddon children='N° de celular' width={155} />
                                    <Input ref={initialRef} type='number' {...register('celNumber')} isDisabled/>
                                </InputGroup>

                                <InputGroup>
                                    <InputLeftAddon children='Cliente y dirección' width={155} />
                                    <Input {...register('client')} isDisabled/>
                                </InputGroup>

                                <InputGroup>
                                    <InputLeftAddon children='Horario de entrega' width={155} />
                                    <Input {...register('deliveryTime')} isDisabled/>
                                </InputGroup>

                                <InputGroup>
                                    <InputLeftAddon children='Delivery $' width={155} />
                                    <Input type='number' {...register('delivery')} isDisabled/>
                                </InputGroup>

                                <InputGroup>
                                    <InputLeftAddon children='Descripción' width={155} />
                                    <Input {...register('description')} isDisabled/>
                                </InputGroup>

                                <TableContainer paddingTop="15px">
                                    <Table variant='striped' size="sm" colorScheme='blackAlpha' {...getTableProps()}>
                                        <Thead>
                                            {
                                                // Recorremos las columnas que previamente definimos
                                                headerGroups.map(headerGroup => (
                                                    // Añadimos las propiedades al conjunto de columnas
                                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                                    {
                                                        // Recorremos cada columna del conjunto para acceder a su información
                                                        headerGroup.headers.map((column) => (
                                                        // Añadimos las propiedades a cada celda de la cabecera
                                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                            {
                                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                                            column.render("Header")
                                                            }
                                                        </Th>
                                                        ))
                                                    }
                                                    </Tr>
                                                ))
                                            }
                                        </Thead>
                                        <Tbody {...getTableBodyProps()}>
                                            {
                                                // Recorremos las filas
                                                page.map(row => {
                                                    // Llamamos a la función que prepara la fila previo renderizado
                                                    prepareRow(row);
                                                    return (
                                                    // Añadimos las propiedades a la fila
                                                    <Tr {...row.getRowProps()}>
                                                        {
                                                        // Recorremos cada celda de la fila
                                                        row.cells.map((cell) => {
                                                            // Añadimos las propiedades a cada celda de la fila
                                                            return (
                                                            <Td {...cell.getCellProps()}>
                                                                {
                                                                // Pintamos el contenido de la celda
                                                                cell.render("Cell")
                                                                }
                                                            </Td>
                                                            );
                                                        })
                                                        }
                                                    </Tr>
                                                    );
                                                })
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                                <Flex p={4}>
                                    <Spacer/>
                                    <FormLabel textAlign="right">Total: </FormLabel>
                                    <FormLabel textAlign="left">${totalTicket}</FormLabel>
                                </Flex>

                            </Stack>                            
                        </form>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default TicketLast