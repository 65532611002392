import { Heading, Box, HStack } from '@chakra-ui/react'
import FoodEdit from './FoodEdit'
import FoodRemove from './FoodRemove'
import '../css/stylesheet.css'

const Food = ({ id, name, subname, description, price, handleSubmitModal }) => {

    const handleSubmitEdit = () => {
        handleSubmitModal()
    }

    return (
        <div className="col-12 col-md-6 col-lg-3">
            <div className="card">
                <div className="card-body">
                    <Heading className="card-title title-card">{subname}</Heading>
                    <Heading size="lg" className="card-title subtitle-card">{name}</Heading>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <p className="description-food">Descripción</p>
                        {description}
                    </li>
                    <li className="list-group-item">
                        <p className="description-food">Precio</p>
                        ${price}
                    </li>
                </ul>
                <div className="card-body">
                    <Box>
                        <HStack>
                            <FoodEdit idFood={id} handleSubmitEdit={handleSubmitEdit} />
                            <FoodRemove idFood={id} handleSubmitEdit={handleSubmitEdit} />
                        </HStack>
                    </Box>
                </div>
            </div>
        </div>
    )
    
}

export default Food