import { Box, InputGroup, InputLeftAddon, Select, CircularProgress, HStack } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import Food from './Food'
import FoodAdd from './FoodAdd'
import FoodsUpdatePrice from './FoodsUpdatePrice'

const Foods = () => {
    const [foods, setFoods] = useState([])
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [category, setCategory] = useState(null)
    const [submitModal, setSubmitModal] = useState(false)
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    useEffect(() => {
        const accessToken = cookies.accessToken

        setLoading(true)
        
        fetch("https://api.pedidoselchavo.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            logout()
        })
        
        fetch("https://api.pedidoselchavo.com.ar/api/food/category/" + category, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrFoods = data.map(food => {
                return { 
                    id: food._id,
                    subname: food.subname,
                    name: food.name,
                    description: food.description,
                    category: food.category,
                    price: parseFloat(food.price)
                }
            })
            setFoods(arrFoods)
            setLoading(false)
        })
        .catch(error => {
            logout()
        })
        
    }, [submitModal])

    const onChange = (e) => {
        setCategory(e.target.value || null)
        setSubmitModal(!submitModal)
    }

    const handleSubmitModal = () => {
        setSubmitModal(!submitModal)
    }

    return (
        <>
            <Box className="content">
                <HStack>
                    <FoodAdd handleSubmitModal={handleSubmitModal}/>
                    <FoodsUpdatePrice handleSubmitModal={handleSubmitModal}/>
                </HStack>

                <Box paddingTop="15px">
                    <InputGroup>
                        <InputLeftAddon children='Categoría' width={140} />
                        <Select placeholder='-Todas-' onChange={(e) => onChange(e)}>
                        {categories.map(category => {
                            return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        )})}
                        </Select>
                    </InputGroup>
                </Box>

                {loading && <Box paddingTop="15px" textAlign='center'> 
                    <CircularProgress isIndeterminate color='green.300' />
                </Box>}

                {!loading && <Box paddingTop="15px">
                    <Box className="row g-3">
                        {foods.map(food => <Food key={food.id} {...food} handleSubmitModal={handleSubmitModal} />)}
                    </Box>
                </Box> }
                
            </Box>
        
        </>
    )
}

export default Foods