import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, CircularProgress, HStack, Select } from '@chakra-ui/react'
import { Button, DropdownButton, Dropdown, ButtonGroup, Modal, Row, Col, Form} from 'react-bootstrap'
import { useTable, useRowSelect } from "react-table"
import useColumnsFoods from "../hooks/useColumnsFoods"
import { useEffect, useState, forwardRef, useRef } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import DatePicker from 'react-datepicker'
import format from 'date-fns/format'
const moment = require('moment')
moment.locale('en')

const MenuAdd3 = ({ handleSubmitModal }) => {
    const [data, setData] = useState([])
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)

    const [showModalSingleDay, setShowModalSingleDay] = useState(false)
    const [dateSingleDay, setDateSingleDay] = useState(new Date())
    const [isOpenDateSingleDay, setIsOpenDateSingleDay] = useState(false)
    let [titleDropdownFormSingleDay, setTitleDropdownFormSingleDay] = useState("Mediodía")

    const [showModalMultiDay, setShowModalMultiDay] = useState(false)
    let [titleDropdownFormMultiDay, setTitleDropdownFormMultiDay] = useState("Mediodía")
    const [daysSelected, setDaysSelected] = useState([])

    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])
 
    const getFoods = (category) => {
        const accessToken = cookies.accessToken

        setLoading(true)

        fetch("https://api.pedidoselchavo.com.ar/api/food/category/" + category, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrFoods = data.map(food => {
                return { 
                    id: food._id,
                    subname: food.subname,
                    name: food.name,
                    price: "$" + parseFloat(food.price).toFixed(2)
                }
            })
            setData(arrFoods)
            setLoading(false)
        })
        .catch(error => {
            logout()
        })
    }

    const getCategories = () => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            logout()
        })
    }

    const IndeterminateCheckbox = forwardRef(
        ({ indeterminate, ...rest }, ref) => {
          const defaultRef = useRef()
          const resolvedRef = ref || defaultRef
      
          useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
          }, [resolvedRef, indeterminate])
      
          return (
            <>
              <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
          )
        }
      )

    const columns = useColumnsFoods();
    const table = useTable({ 
        columns, data, initialState: {hiddenColumns: ["id"]} }, 
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
              },
              ...columns,
            ])
          }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state
      } = table;

    const { selectedRowIds } = state

    //SINGLE DAY ----------------------------------------------------------------------------
    const handleCloseModalSingleDay = () => setShowModalSingleDay(false);
    const handleShowModalSingleDay = () => {
        getFoods(null)
        getCategories()
        setDateSingleDay(new Date())
        setShowModalSingleDay(true);
    }

    const handleClickDateSingleDay = (e) => {
        e.preventDefault();
        setIsOpenDateSingleDay(!isOpenDateSingleDay);
    }

    const handleChangeDateSingleDay = (e) => {
        setIsOpenDateSingleDay(!isOpenDateSingleDay);
        setDateSingleDay(e);
    }

    const changeValueDropdownFormSingleDay = (e) => {
        setTitleDropdownFormSingleDay(e.currentTarget.textContent)
    }

    const handleChangeCategory = (category) => {
        if (category === "") category = null
        getFoods(category)
    }

    const handleSubmitFormSingleDay = () => {
        const accessToken = cookies.accessToken

        moment.locale('en')
        let hour
        const arrFoodsSelected = []
        const arrFoods = table.selectedFlatRows

        for (let i=0; i < arrFoods.length; i++) {
            arrFoodsSelected.push(arrFoods[i].values.subname)
        }

        if (titleDropdownFormSingleDay === "Noche"){
            hour = "night"
        } else {
            hour = "day"
        }
        
        const menu = {
            //date: [moment(dateSingleDay)],
            date: [ Date.parse(moment(dateSingleDay).format('L')) ],
            hour: hour,
            foods: arrFoodsSelected
        }

        fetch("https://api.pedidoselchavo.com.ar/api/menu", { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(menu)}
        )
        .then(response => response.json())
        .then(data => {
            const foodsMenu = data.foods.map(food => {
                return { 
                    id: food.id,
                    subname: food.subname,
                    name: food.name,
                    price: food.price
                }
            })
            handleSubmitModal(foodsMenu, titleDropdownFormSingleDay, dateSingleDay)
            handleCloseModalSingleDay()
        })
        .catch(error => {
            logout()
        })
    }


    //MULTI DAY ----------------------------------------------------------------------------
    const handleCloseModalMultiDay = () => setShowModalMultiDay(false);
    const handleShowModalMultiDay = () => {
        getFoods(null)
        getCategories()
        setDaysSelected([])
        setShowModalMultiDay(true);
    }

    const changeValueDropdownFormMultiDay = (e) => {
        setTitleDropdownFormMultiDay(e.currentTarget.textContent)
    }

    const setCheckboxDaysFormMultiDay = (value) => {
        const arrDays = daysSelected
        const existDay = daysSelected.findIndex(day => day === value)
        if (existDay > -1) {
            arrDays.splice(existDay, 1)
            setDaysSelected(arrDays)
        } else {
            arrDays.push(value)
            setDaysSelected(arrDays)
        }
    }

    const handleSubmitFormMultiDay = () => {
        const accessToken = cookies.accessToken

        moment.locale('en')
        let hour
        const arrFoodsSelected = []
        const arrFoods = table.selectedFlatRows

        for (let i=0; i < arrFoods.length; i++) {
            arrFoodsSelected.push(arrFoods[i].values.subname)
        }

        if (titleDropdownFormMultiDay === "Noche"){
            hour = "night"
        } else {
            hour = "day"
        }

        //HAGO ESTE CALCULO PARA CARGAR EL MENU DESDE HOY HASTA EL ULTIMO DIA DEL MES
        /*const arrDays = []
        const today = new Date()
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        const diffDays = moment(lastDay).diff(moment(today), 'days')
        
        for (let i = 0; i <= diffDays + 1; i++){
            let dayOfWeek = moment(moment().add(i, 'd')).day()
            if (dayOfWeek === 0) dayOfWeek = 7
            const existDay = daysSelected.find(day => day === dayOfWeek)
            if (existDay){
                const date = moment().add(i, 'd')
                arrDays.push(Date.parse(date))
            }
        }*/
        //----------------------------------------------------------------------------

        //HAGO ESTE CALCULO PARA CARGAR EL MENU DESDE HOY HASTA EL ULTIMO DIA DEL AÑO
        const arrDays = []
        const today = new Date()
        const lastDay = new Date(today.getFullYear(), 11, 31)
        const diffDays = moment(lastDay).diff(moment(today), 'days')
        
        for (let i = 0; i <= diffDays + 1; i++){
            let dayOfWeek = moment(moment().add(i, 'd')).day()
            if (dayOfWeek === 0) dayOfWeek = 7
            const existDay = daysSelected.find(day => day === dayOfWeek)
            if (existDay){
                const date = moment().add(i, 'd')
                arrDays.push(Date.parse(moment(date).format('L')))
                //arrDays.push(Date.parse(date))
            }
        }
        //----------------------------------------------------------------------------

        const menu = {
            date: arrDays,
            hour: hour,
            foods: arrFoodsSelected
        }

        fetch("https://api.pedidoselchavo.com.ar/api/menu", { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(menu)}
        )
        .then(response => response.json())
        .then(data => {
            const foodsMenu = data.foods.map(food => {
                return { 
                    id: food.id,
                    subname: food.subname,
                    name: food.name,
                    price: food.price
                }
            })
            handleSubmitModal(foodsMenu, titleDropdownFormMultiDay, arrDays[0])
            handleCloseModalMultiDay()
        })
        .catch(error => {
            logout()
        })
    }

    return (
        <>
            <ButtonGroup>
                <DropdownButton title="Añadir menú">
                    <Dropdown.Item eventKey="1" onClick={ () => handleShowModalSingleDay(true) }>Día individual</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={ () => handleShowModalMultiDay(true) }>Grupo de días</Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>

            <Modal show={showModalSingleDay} onHide={handleCloseModalSingleDay} className="modal-xl">
                <Modal.Header closeButton>
                <Modal.Title>Agregar comida al menú</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HStack spacing={6}>
                        <Box>
                            <span className="menu-style">Fecha:</span>
                            <Button variant="primary" onClick={handleClickDateSingleDay}>
                                {format(dateSingleDay, "dd/MM/yyyy")}
                            </Button>
                            {isOpenDateSingleDay && (
                                <DatePicker selected={dateSingleDay} onChange={handleChangeDateSingleDay} inline />
                            )}
                        </Box>
                        <Box>
                            <ButtonGroup>
                            <span className="menu-style">Horario:</span>
                            <DropdownButton id="hour" title={titleDropdownFormSingleDay}>
                                <Dropdown.Item eventKey="1" onClick={changeValueDropdownFormSingleDay}>Mediodía</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={changeValueDropdownFormSingleDay}>Noche</Dropdown.Item>
                            </DropdownButton>
                            </ButtonGroup>
                        </Box>
                    </HStack>

                    <br/>

                    <HStack>
                        <span className="menu-style">Categoría:</span>
                        <Select placeholder='-Selecciona-' width="250px" onChange={(e) => handleChangeCategory(e.target.value)}>
                        {categories.map(category => {
                            return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        )})}
                        </Select>
                    </HStack>

                    <br/>

                    {loading && <Box paddingTop="15px" textAlign='center'> 
                        <CircularProgress isIndeterminate color='green.300' />
                    </Box>}

                    {!loading && <TableContainer paddingTop="15px">
                        <Table variant='striped' size="md" colorScheme='blackAlpha' {...getTableProps()}>
                            <Thead>
                            {headerGroups.map(headerGroup => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                                ))}
                                </Tr>
                            ))}
                            </Thead>
                            <Tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                    return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                    })}
                                </Tr>
                                )
                            })}
                            </Tbody>
                        </Table>
                    </TableContainer>}
                </Modal.Body>
                <Modal.Footer>
                    <HStack>
                        <Button variant="primary" onClick={handleSubmitFormSingleDay}>Guardar</Button>
                        <Button variant="secondary" onClick={handleCloseModalSingleDay}>Cerrar</Button>
                    </HStack>
                </Modal.Footer>
            </Modal>



            <Modal show={showModalMultiDay} onHide={handleCloseModalMultiDay} className="modal-xl">
                <Modal.Header closeButton>
                <Modal.Title>Agregar comida al menú</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="menu-style">Días</span>
                    <Row>
                        <Col><Form.Check inline label="Lunes" type="checkbox" onClick={ () => setCheckboxDaysFormMultiDay(1) }/></Col>
                        <Col><Form.Check inline label="Martes" type="checkbox" onClick={ () => setCheckboxDaysFormMultiDay(2) }/></Col>
                        <Col><Form.Check inline label="Miércoles" type="checkbox" onClick={ () => setCheckboxDaysFormMultiDay(3) }/></Col>
                        <Col><Form.Check inline label="Jueves" type="checkbox" onClick={ () => setCheckboxDaysFormMultiDay(4) }/></Col>
                    </Row>
                    <Row>
                        <Col><Form.Check inline label="Viernes" type="checkbox" onClick={ () => setCheckboxDaysFormMultiDay(5) }/></Col>
                        <Col><Form.Check inline label="Sábado" type="checkbox" onClick={ () => setCheckboxDaysFormMultiDay(6) }/></Col>
                        <Col><Form.Check inline label="Domingo" type="checkbox" onClick={ () => setCheckboxDaysFormMultiDay(7) }/></Col>
                        <Col></Col>
                    </Row>

                    <br/>
                    <ButtonGroup>
                    <span className="menu-style">Horario:</span>
                    <DropdownButton id="hour" title={titleDropdownFormMultiDay}>
                        <Dropdown.Item eventKey="1" onClick={changeValueDropdownFormMultiDay}>Mediodía</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={changeValueDropdownFormMultiDay}>Noche</Dropdown.Item>
                    </DropdownButton>
                    </ButtonGroup>

                    <br/>
                    <br/>
                    
                    <HStack>
                        <span className="menu-style">Categoría:</span>
                        <Select placeholder='-Selecciona-' width="250px" onChange={(e) => handleChangeCategory(e.target.value)}>
                        {categories.map(category => {
                            return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        )})}
                        </Select>
                    </HStack>

                    <br/>

                    {loading && <Box paddingTop="15px" textAlign='center'> 
                        <CircularProgress isIndeterminate color='green.300' />
                    </Box>}

                    {!loading && <TableContainer paddingTop="15px">
                        <Table variant='striped' size="md" colorScheme='blackAlpha' {...getTableProps()}>
                            <Thead>
                            {headerGroups.map(headerGroup => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                                ))}
                                </Tr>
                            ))}
                            </Thead>
                            <Tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                    return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                    })}
                                </Tr>
                                )
                            })}
                            </Tbody>
                        </Table>
                    </TableContainer>}
                    
                </Modal.Body>
                <Modal.Footer>
                <HStack>
                    <Button variant="primary" onClick={handleSubmitFormMultiDay}>Guardar</Button>
                    <Button variant="secondary" onClick={handleCloseModalMultiDay}>Cerrar</Button>
                </HStack>
                </Modal.Footer>
            </Modal>

            
        </>
    )

}

export default MenuAdd3