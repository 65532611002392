import { Stack, InputGroup, InputLeftAddon, useDisclosure, useToast, Input, Spacer, Button,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex } from '@chakra-ui/react'
import { useRef } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'

const CategoryAdd = ({ handleSubmitModal }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const toast = useToast()
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    const handleOpen = () => {
        reset()
        onOpen()
    }

    const onSubmit = (data) => {
        const accessToken = cookies.accessToken
        const category = {name: data.name}

        fetch("https://api.pedidoselchavo.com.ar/api/category", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(category)
        })
        .catch(error => {
            logout()
        })

        toast({
            title: "¡Categoría añadida!",
            status: "success",
            duration: 3000,
            isClosable: true
        });

        handleSubmitModal()
        onClose()
    }

    return (
        <>
            <Button colorScheme="linkedin" onClick={handleOpen}>Nueva categoría</Button>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Nueva categoría</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={4}>
                        <InputGroup>
                            <InputLeftAddon children='Nombre' width={127} />
                            <Input ref={initialRef} {...register('name', {required: 'Ingrese el nombre'})} />
                        </InputGroup>
                        {errors.name && <AlertPop title={errors.name.message} />}

                    </Stack>

                    <Flex p={4}>
                        <Spacer/>
                        <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                        <Button onClick={onClose}>Cancelar</Button>
                    </Flex>
                    
                </form>
                </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )

}

export default CategoryAdd