import { useMemo } from "react";

export default function useColumnsTicket() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "_id"
     },
     {
       Header: "Cant.",
       accessor: "count"
     },
     {
       Header: "Abrev.",
       accessor: "subname"
     },
     {
       Header: "Comida",
       accessor: "name"
     },
     {
       Header: "Precio",
       accessor: "price"
     },
     {
       Header: "Total",
       accessor: "totalPrice"
     }
   ],
   []
 );

 return columns;
}