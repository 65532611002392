import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 5,
    backgroundColor: '#fff'
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  },
  /*leftColumn: {
    flexDirection: 'column',
    //width: 285,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      //width: 200,
    },
  },
  rightColumn: {
    width: 285
  },*/
  title: {
    textAlign: "center",
    textDecoration: 'underline',
    fontWeight: "bold",
    fontSize: "15"
  },
  subtitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "13"
  },
  detail: {
    fontWeight: "bold",
    fontSize: "10"
  },
  detailBold: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "13"
  },
  enter: {
    color: "white"
  },
  finalPrice: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "15"
  }
});

// Create Document Component
const PDF = ({client, foods, delivery, finalPrice, date, deliveryTime, celNumber, id, description}) => (
  
  <Document>
    <Page size={[194, 857]} style={styles.page}>
        <View style={styles.container}>
            {/*<View style={styles.leftColumn} >*/}
                <Text style={styles.title}>EL CHAVO DE LA 9</Text>
                <Text style={styles.subtitle}>BRASERÍA - ROTISERÍA</Text>
                <Text style={styles.enter}>enter</Text>
                <Text style={styles.detailBold}>Fecha: {date}</Text>
                <Text style={styles.detailBold}>Pedido N°: {id}</Text>
                <Text style={styles.enter}>enter</Text>
                {foods.map(food => (<Text style={styles.detail} key={food.subName}>{food.count} {food.subName} {food.name} ${food.finalPrice}</Text>))}
                <Text style={styles.enter}>enter</Text>
                <Text style={styles.detail}>Descripción: {description}</Text>
                <Text style={styles.enter}>enter</Text>
                <Text style={styles.detail}>Delivery: ${delivery}</Text>
                <Text style={styles.enter}>enter</Text>
                <Text style={styles.detailBold}>Cliente: {client}</Text>
                <Text style={styles.detailBold}>Celular: {celNumber}</Text>
                <Text style={styles.enter}>enter</Text>
                <Text style={styles.finalPrice}>Horario: {deliveryTime}</Text>
                <Text style={styles.finalPrice}>TOTAL: ${finalPrice}</Text>
            {/*</View>*/}
            {/*<View style={styles.rightColumn}>
            <Text style={styles.title}>Rotisería "EL QUINCHO"</Text>
                <Text style={styles.enter}>enter</Text>
                <Text style={styles.detail}>Fecha: {date}</Text>
                <Text style={styles.detail}>Pedido N°: {id}</Text>
                <Text style={styles.detail}>Cliente: {client}</Text>
                <Text style={styles.enter}>enter</Text>
                {foods.map(food => (<Text style={styles.detail} key={food.subName}>{food.count} {food.subName} {food.name} ${food.finalPrice}</Text>))}
                <Text style={styles.enter}>enter</Text>
                <Text style={styles.detail}>Delivery: ${delivery}</Text>
                <Text style={styles.enter}>enter</Text>
                <Text style={styles.detail}>PRECIO: ${finalPrice}</Text>
</View>*/}
        </View>
    </Page>
  </Document>
);

export default PDF