import { useMemo } from "react";

export default function useColumnsTickets() {
 const columns = useMemo(
   () => [
     {
       Header: "ID",
       accessor: "_id"
     },
     {
       Header: "N° Pedido",
       accessor: "id"
     },
     {
       Header: "Cliente",
       accessor: "client"
     },
     {
       Header: "N° Celular",
       accessor: "celNumber"
     },
     {
       Header: "Total",
       accessor: "finalPrice"
     },
     {
       Header: "Estado",
       accessor: "isCancel"
     }
   ],
   []
 );

 return columns;
}