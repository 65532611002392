import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
//import { loginRequest, verifyTokenRequest } from "../api/auth";
import { useCookies } from 'react-cookie';

const loginRequest = async(user) => {
  return fetch('https://api.pedidoselchavo.com.ar/api/user/login', {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(user)
  })
  .then(response => response.json())
  .then(data => {
    return data;
  })
}

const verifyTokenRequest = async() => {
  return fetch('https://api.pedidoselchavo.com.ar/api/user/verify',
  {
    credentials: "include"
  })
  .then(response => response.json())
  .then(data => {
    return data;
  })
}

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within a AuthProvider");
  return context;
};

export const AuthProvider = ({ children }) => {
  //const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //const [errors, setErrors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken'])

  // clear errors after 5 seconds
  useEffect(() => {
    //if (errors.length > 0) {
    if (error !== null) {
      const timer = setTimeout(() => {
        //setErrors([]);
        setError(null)
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error/*errors*/]);

  const signin = async (user) => {
    try {
      const res = await loginRequest(user);
      //setUser(res.data);
      //localStorage.setItem("accessToken", JSON.stringify(res.accessToken))
      if (res.accessToken) {
        setCookie('accessToken', res.accessToken, { path: '/' })
        setIsAuthenticated(true);
        setError(null)
      } else {
        setError("Login incorrecto")
      }
    } catch (error) {
      //console.log(error);
      // setErrors(error.response.data.message);
    }
  };

  const logout = () => {
    //Cookies.remove("token");
    removeCookie("accessToken")
    //setUser(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const checkLogin = async () => {
      /*const cookies = Cookies.get();
      if (!cookies.token) {*/
      const cookie = cookies.accessToken
      if (!cookie) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }

      try {
        const res = await verifyTokenRequest();
        //if (!res) return setIsAuthenticated(false);
        if (res.accessToken === cookie) {
          setIsAuthenticated(true);
          //setUser(res.data);
          setLoading(false);
        } else {
          removeCookie("accessToken")
          setIsAuthenticated(false);
          setLoading(false);
        }
        
      } catch (error) {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };
    checkLogin();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        //user,
        signin,
        logout,
        isAuthenticated,
        //errors,
        error,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;