import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, CircularProgress, HStack, Select, InputGroup,
        InputLeftAddon, Input, useToast, Button } from '@chakra-ui/react'
import { Modal} from 'react-bootstrap'
import { useTable, useRowSelect } from "react-table"
import useColumnsFoods from "../hooks/useColumnsFoods"
import { useEffect, useState, forwardRef, useRef } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'

const FoodsUpdatePrice = ({ handleSubmitModal }) => {
    const [data, setData] = useState([])
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [percentage, setPercentage] = useState(0)
    const toast = useToast()
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])
 
    const getFoods = (category) => {
        const accessToken = cookies.accessToken

        setLoading(true)

        fetch("https://api.pedidoselchavo.com.ar/api/food/category/" + category, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrFoods = data.map(food => {
                return { 
                    id: food._id,
                    subname: food.subname,
                    name: food.name,
                    price: "$" + parseFloat(food.price).toFixed(2)
                }
            })
            setData(arrFoods)
            setLoading(false)
        })
        .catch(error => {
            logout()
        })
    }

    const getCategories = () => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            logout()
        })
    }

    const IndeterminateCheckbox = forwardRef(
        ({ indeterminate, ...rest }, ref) => {
          const defaultRef = useRef()
          const resolvedRef = ref || defaultRef
      
          useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
          }, [resolvedRef, indeterminate])
      
          return (
            <>
              <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
          )
        }
      )

    const columns = useColumnsFoods();
    const table = useTable({ 
        columns, data, initialState: {hiddenColumns: ["id"]} }, 
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
              },
              ...columns,
            ])
          }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state
      } = table;

    const { selectedRowIds } = state

    const handleClose = () => setShowModal(false);
    const handleOpen = () => {
        getFoods(null)
        getCategories()
        setPercentage(0)
        setShowModal(true);
    }

    const handleChangeCategory = (category) => {
        if (category === "") category = null
        getFoods(category)
    }

    const handleSubmit = () => {
        if (percentage === "" || parseInt(percentage) === 0) {
            toast({
                title: "Ingrese un porcentaje válido para realizar la actualización",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }
        
        const arrFoodsSelected = []
        const arrFoods = table.selectedFlatRows

        for (let i=0; i < arrFoods.length; i++) {
            arrFoodsSelected.push(arrFoods[i].values.subname)
        }

        if (arrFoodsSelected.length === 0) {
            toast({
                title: "Seleccione las comidas que desee actualizar",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return
        }

        const info = {
            foods: arrFoodsSelected,
            percentage: parseFloat(percentage)
        }

        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/food/updatePrice", { 
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            },
            body: JSON.stringify(info)}
        )
        .then(response => response.json())
        .then(data => {
            toast({
                title: "Los precios se actualizaron correctamente",
                status: "success",
                duration: 3000,
                isClosable: true
            });

            handleSubmitModal()
            handleClose()
        })
        .catch(error => {
            console.log(error)
            /*authService.logout()
            navigate("/");
            window.location.reload();*/
        })
    }

    return (
        <>
            <Button colorScheme="linkedin" onClick={handleOpen}>Actualizar precios</Button>

            <Modal show={showModal} onHide={handleClose} className="modal-lg">
                <Modal.Header closeButton>
                <Modal.Title>Actualizar precios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup>
                        <InputLeftAddon children='Porcentaje' width={127} />
                        <Input type='number' value={percentage} onChange={(e) => setPercentage(e.target.value)} />
                    </InputGroup>

                    <br/>

                    <HStack>
                        <span className="menu-style">Categoría:</span>
                        <Select placeholder='-Selecciona-' width="250px" onChange={(e) => handleChangeCategory(e.target.value)}>
                        {categories.map(category => {
                            return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        )})}
                        </Select>
                    </HStack>

                    <br/>

                    {loading && <Box paddingTop="15px" textAlign='center'> 
                        <CircularProgress isIndeterminate color='green.300' />
                    </Box>}

                    {!loading && <TableContainer paddingTop="15px">
                        <Table variant='striped' size="md" colorScheme='blackAlpha' {...getTableProps()}>
                            <Thead>
                            {headerGroups.map(headerGroup => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                                ))}
                                </Tr>
                            ))}
                            </Thead>
                            <Tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                    return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                    })}
                                </Tr>
                                )
                            })}
                            </Tbody>
                        </Table>
                    </TableContainer>}
                </Modal.Body>
                <Modal.Footer>
                    <HStack>
                        <Button colorScheme="linkedin" onClick={handleSubmit}>Guardar</Button>
                        <Button colorScheme="gray" onClick={handleClose}>Cerrar</Button>
                    </HStack>
                </Modal.Footer>
            </Modal>            
        </>
    )

}

export default FoodsUpdatePrice