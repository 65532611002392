import { Heading, HStack, Button, Spacer, Tooltip, Flex, Box } from '@chakra-ui/react'
import { BsXCircle } from "react-icons/bs"
import '../css/stylesheet.css'
const moment = require('moment')

const Ticket = ({client, foods, delivery, finalPrice, date, deliveryTime, celNumber, id, isPrint, delivered, description, handleShowModalPDF, handleDelivered, handleCancel}) => {
    moment.locale('es')
    date = moment(date).add(3, "h").format('L')
    const arrDate = date.split("/")
    const newDate = arrDate[1] + "/" + arrDate[0] + "/" + arrDate[2]

    return (
        <div className="col-12 col-md-6 col-lg-3">
            <div className="card">
                <HStack>
                    <Spacer/>
                    <Tooltip label="Cancelar pedido"><Button size="xs" backgroundColor="transparent" onClick={() => handleCancel(id, celNumber)}><BsXCircle/></Button></Tooltip>
                </HStack>
                <div className="card-body card-body-header">
                    {!isPrint && <Heading size="lg" className="card-title title-card-noprint">Pedido: {id}</Heading>}
                    {isPrint && <Heading size="lg" className="card-title title-card-print">Pedido: {id}</Heading>}
                    <Heading size="sm" className="card-title subtitle-card">{client}</Heading>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <p className="description-food">Comidas</p>
                        {foods.map(ticket => <p key={ticket.subName}>{ticket.count} {ticket.subName} {ticket.name}</p>)}
                    </li>
                    <li className="list-group-item">
                        <p className="description-food">Precio</p>
                        ${finalPrice}
                    </li>
                    <li className="list-group-item">
                        <p className="description-food">Delivery</p>
                        {deliveryTime}
                    </li>
                </ul>
                <div className="card-body">
                    <HStack>
                        {/*<button type="button" className="btn btn-secondary" onClick={() => handleShowModalPDF(id, client, foods, delivery, finalPrice, newDate, deliveryTime, celNumber, isPrint)}>Imprimir</button>
                        <button type="button" className="btn btn-secondary" onClick={() => handleDelivered(celNumber)}>Entregado</button> 8 24/06*/}
                        <Button colorScheme='blackAlpha' onClick={() => handleShowModalPDF(id, client, foods, delivery, finalPrice, newDate, deliveryTime, celNumber, isPrint, description)}>Imprimir</Button>
                        {!delivered && <Button colorScheme='blackAlpha' onClick={() => handleDelivered(id, celNumber)}>Entregado</Button>}
                        {delivered && <Button colorScheme='blackAlpha' isDisabled='true'>Entregado</Button> /*9 24/06* JUNTO CON LAS 2 LINEAS ANTERIORES*/}
                    </HStack>
                </div>
            </div>
        </div>
    )
    
}

export default Ticket