import { Stack, InputGroup, InputLeftAddon, Select, useDisclosure, useToast, Input, Spacer, Button,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex } from '@chakra-ui/react'
import { useState, useRef } from 'react'
import { useAuth } from "../services/authService"
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import AlertPop from './AlertPop'

const FoodEdit = ({ idFood, handleSubmitEdit }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()
    const toast = useToast()
    const [categories, setCategories] = useState([])
    const [originalFood, setOriginalFood] = useState("")
    const [subname, setSubname] = useState("")
    const { logout } = useAuth()
    const [cookies] = useCookies(['accessToken'])

    const handleOpen = () => {
        const accessToken = cookies.accessToken

        fetch("https://api.pedidoselchavo.com.ar/api/category", {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            const arrCategories = data.map(category => {
                return { 
                    id: category._id,
                    name: category.name
                }
            })
            setCategories(arrCategories)
        })
        .catch(error => {
            logout()
        })
        
        fetch("https://api.pedidoselchavo.com.ar/api/food/" + idFood, {
            headers: {
                'Content-Type': 'application/json',
                "x-auth-token": accessToken
            }
        })
        .then(response => response.json())
        .then(data => {
            setValue("subname", data.subname)
            setValue("name", data.name)
            setValue("description", data.description)
            setValue("category", data.category)
            setValue("price", parseFloat(data.price))
            setSubname(data.subname)
            setOriginalFood(data)
        })
        .catch(error => {
            logout()
        })

        onOpen()
    }

    const onSubmit = (infoFood) => {
        const accessToken = cookies.accessToken

        infoFood.subname= infoFood.subname.toUpperCase()
        
        if (originalFood.subname === infoFood.subname) {
            const food = {
                subname: infoFood.subname,
                name: infoFood.name,
                description: infoFood.description,
                category: infoFood.category,
                price: parseFloat(infoFood.price)
            }
    
            fetch("https://api.pedidoselchavo.com.ar/api/food/" + idFood, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                },
                body: JSON.stringify(food)
            })
            .catch(error => {
                logout()
            })
    
            toast({
                title: "¡Comida editada!",
                status: "success",
                duration: 3000,
                isClosable: true
            });
    
            handleSubmitEdit()
            onClose()
        } else {
            fetch("https://api.pedidoselchavo.com.ar/api/food/subname/" + infoFood.subname, {
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": accessToken
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data === null) {
                    const food = {
                        subname: infoFood.subname,
                        name: infoFood.name,
                        description: infoFood.description,
                        category: infoFood.category,
                        price: parseFloat(infoFood.price)
                    }
            
                    fetch("https://api.pedidoselchavo.com.ar/api/food/" + idFood, {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json',
                            "x-auth-token": accessToken
                        },
                        body: JSON.stringify(food)
                    })
                    .catch(error => {
                        logout()
                    })
            
                    toast({
                        title: "¡Comida editada!",
                        status: "success",
                        duration: 3000,
                        isClosable: true
                    });
            
                    handleSubmitEdit()
                    onClose()
                } else {
                    toast({
                        title: "Ya existe una comida con esa abreviatura",
                        status: "error",
                        duration: 3000,
                        isClosable: true
                    });
                }
            })
            .catch(error => {
                console.log(error)
            })

        }
    }

    const onChange = (e) => {
        setSubname(e.target.value.toUpperCase())
    }

    return (
        <>
            <Button colorScheme="blackAlpha" onClick={handleOpen}>Modificar</Button>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Modificar comida</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={4}>

                        <InputGroup>
                            <InputLeftAddon children='Abreviatura' width={127} />
                            <Input ref={initialRef} value={subname} {...register('subname', {onChange: (e) => onChange(e), maxLength: 3, required: 'Ingrese la abreviatura'})} />
                        </InputGroup>
                        {errors.subname && <AlertPop title={errors.subname.message} />}

                        <InputGroup>
                            <InputLeftAddon children='Nombre' width={127} />
                            <Input {...register('name', {required: 'Ingrese el nombre'})} />
                        </InputGroup>
                        {errors.name && <AlertPop title={errors.name.message} />}

                        <InputGroup>
                            <InputLeftAddon children='Descripción' width={127} />
                            <Input {...register('description')} />
                        </InputGroup>

                        <InputGroup>
                            <InputLeftAddon children='Categoría' width={127} />
                            <Select placeholder='-Selecciona-' {...register('category', {required: 'Seleccione la categoría'})}>
                            {categories.map(category => {
                                return (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            )})}
                            </Select>
                        </InputGroup>
                        {errors.category && <AlertPop title={errors.category.message} />}

                        <InputGroup>
                            <InputLeftAddon children='Precio $' width={127} />
                            <Input type='number' {...register('price', {required: 'Ingrese el precio'})} />
                        </InputGroup>
                        {errors.price && <AlertPop title={errors.price.message} />}

                    </Stack>

                    <Flex p={4}>
                        <Spacer/>
                        <Button type='submit' colorScheme='blue' mr={3}>Guardar</Button>
                        <Button onClick={onClose}>Cancelar</Button>
                    </Flex>
                    
                </form>
                </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default FoodEdit