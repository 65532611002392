import { Heading, Box, HStack } from '@chakra-ui/react'
import CategoryEdit from './CategoryEdit'
import CategoryRemove from './CategoryRemove'
import '../css/stylesheet.css'

const Category = ({ id, name, handleSubmitModal }) => {

    const handleSubmitEdit = () => {
        handleSubmitModal()
    }

    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
                <div className="card-body">
                    {/*<Heading className="card-title title-card">{id}</Heading>*/}
                    <Heading size="lg" className="card-title title-card">{name}</Heading>
                </div>
                <div className="card-body">
                    <Box>
                        <HStack>
                            <CategoryEdit idCategory={id} handleSubmitEdit={handleSubmitEdit} />
                            <CategoryRemove idCategory={id} handleSubmitEdit={handleSubmitEdit} />
                        </HStack>
                    </Box>
                </div>
            </div>
        </div>
    )
    
}

export default Category